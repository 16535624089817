/** 
 * THIS FILE IS AUTO-GENERATED, DO NOT EDIT
 */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601Date: string;
  ISO8601DateTime: string;
  Json: JsonValue;
};

export enum Account {
  Corporate = 'CORPORATE',
  Personal = 'PERSONAL'
}

export enum AccountStatus {
  Activated = 'ACTIVATED',
  Archived = 'ARCHIVED',
  Deactivated = 'DEACTIVATED',
  InReview = 'IN_REVIEW',
  OnHold = 'ON_HOLD',
  Problematic = 'PROBLEMATIC',
  ReadyForReview = 'READY_FOR_REVIEW',
  Rejected = 'REJECTED'
}

export type Adjustment = {
  __typename?: 'Adjustment';
  adjustmentPattern: AdjustmentPattern;
  amount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  descriptionForBrand?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lineItem?: Maybe<LineItem>;
  memo?: Maybe<Scalars['String']>;
  salesReport?: Maybe<SalesReport>;
  shop: Brand;
  sign: Sign;
  staff: Staff;
  yearMonth: Scalars['String'];
};

/** The connection type for Adjustment. */
export type AdjustmentConnection = {
  __typename?: 'AdjustmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AdjustmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Adjustment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of AdjustmentCreate */
export type AdjustmentCreateInput = {
  adjustmentPatternId: Scalars['ID'];
  amount: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  descriptionForBrand?: InputMaybe<Scalars['String']>;
  lineItemId?: InputMaybe<Scalars['ID']>;
  memo?: InputMaybe<Scalars['String']>;
  shopId: Scalars['ID'];
  sign: Sign;
  yearMonth: Scalars['String'];
};

/** Autogenerated return type of AdjustmentCreate */
export type AdjustmentCreatePayload = {
  __typename?: 'AdjustmentCreatePayload';
  adjustment: Adjustment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type AdjustmentEdge = {
  __typename?: 'AdjustmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Adjustment>;
};

export type AdjustmentPattern = {
  __typename?: 'AdjustmentPattern';
  amount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  patternType: Pattern;
  sign?: Maybe<Sign>;
};

/** Autogenerated input type of AdjustmentsBulkDelete */
export type AdjustmentsBulkDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of AdjustmentsBulkDelete */
export type AdjustmentsBulkDeletePayload = {
  __typename?: 'AdjustmentsBulkDeletePayload';
  adjustments: Array<Adjustment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AdminCustomerNotification = {
  __typename?: 'AdminCustomerNotification';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AdminCustomerNotificationCreate */
export type AdminCustomerNotificationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  image?: InputMaybe<ImageInput>;
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of AdminCustomerNotificationCreate */
export type AdminCustomerNotificationCreatePayload = {
  __typename?: 'AdminCustomerNotificationCreatePayload';
  adminCustomerNotification: AdminCustomerNotification;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AdminFile = {
  __typename?: 'AdminFile';
  archived: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  keywords?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  url: Scalars['String'];
};

/** The connection type for AdminFile. */
export type AdminFileConnection = {
  __typename?: 'AdminFileConnection';
  /** A list of edges. */
  edges: Array<AdminFileEdge>;
  /** A list of nodes. */
  nodes: Array<AdminFile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AdminFileCreateInput = {
  files: Array<Scalars['String']>;
};

/** Autogenerated return type of AdminFileCreate */
export type AdminFileCreatePayload = {
  __typename?: 'AdminFileCreatePayload';
  adminFiles: Array<AdminFile>;
};

/** An edge in a connection. */
export type AdminFileEdge = {
  __typename?: 'AdminFileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AdminFile;
};

export type AdminFileUpdateInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  keywords?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of AdminFileUpdate */
export type AdminFileUpdatePayload = {
  __typename?: 'AdminFileUpdatePayload';
  adminFile: AdminFile;
};

export type Announcement = {
  __typename?: 'Announcement';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  popupEnabled: Scalars['Boolean'];
  status?: Maybe<AnnouncementStatus>;
  title?: Maybe<Scalars['String']>;
  viewedByCurrentShop?: Maybe<Scalars['Boolean']>;
};

/** The connection type for Announcement. */
export type AnnouncementConnection = {
  __typename?: 'AnnouncementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AnnouncementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Announcement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AnnouncementEdge = {
  __typename?: 'AnnouncementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Announcement>;
};

export enum AnnouncementStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type AppTabType = {
  __typename?: 'AppTabType';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type Area = {
  __typename?: 'Area';
  bio?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['ID']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  logoImageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pageId?: Maybe<Scalars['ID']>;
  sortOrder: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  vendors: VendorConnection;
  vendorsCount: Scalars['Int'];
};


export type AreaVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The connection type for Area. */
export type AreaConnection = {
  __typename?: 'AreaConnection';
  /** A list of edges. */
  edges: Array<AreaEdge>;
  /** A list of nodes. */
  nodes: Array<Area>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AreaEdge = {
  __typename?: 'AreaEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Area;
};

/** Autogenerated input type of AreaUpdate */
export type AreaUpdateInput = {
  bio?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectionId?: InputMaybe<Scalars['ID']>;
  coverImage?: InputMaybe<ImageInput>;
  id: Scalars['ID'];
  logoImage?: InputMaybe<ImageInput>;
  name?: InputMaybe<Scalars['String']>;
  pageId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of AreaUpdate */
export type AreaUpdatePayload = {
  __typename?: 'AreaUpdatePayload';
  area: Area;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Article = RegionInterface & {
  __typename?: 'Article';
  author: ArticleAuthor;
  blog: Blog;
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  eyecatchImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mainCategory?: Maybe<ArticleCategory>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  region: Region;
  status: ArticleStatus;
  subCategory?: Maybe<ArticleCategory>;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  vendors: Array<Vendor>;
};

export type ArticleAuthor = {
  __typename?: 'ArticleAuthor';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ArticleAuthorType;
};

/** The connection type for ArticleAuthor. */
export type ArticleAuthorConnection = {
  __typename?: 'ArticleAuthorConnection';
  /** A list of edges. */
  edges: Array<ArticleAuthorEdge>;
  /** A list of nodes. */
  nodes: Array<ArticleAuthor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ArticleAuthorEdge = {
  __typename?: 'ArticleAuthorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ArticleAuthor;
};

export type ArticleAuthorInput = {
  authorType: ArticleAuthorType;
  id: Scalars['ID'];
};

export enum ArticleAuthorType {
  Shop = 'SHOP',
  Staff = 'STAFF'
}

export type ArticleCategory = {
  __typename?: 'ArticleCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for Article. */
export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  /** A list of edges. */
  edges: Array<ArticleEdge>;
  /** A list of nodes. */
  nodes: Array<Article>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of ArticleCreate */
export type ArticleCreatePayload = {
  __typename?: 'ArticleCreatePayload';
  article: Article;
};

/** An edge in a connection. */
export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Article;
};

export type ArticleInput = {
  author: ArticleAuthorInput;
  blogId?: InputMaybe<Scalars['ID']>;
  body?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eyecatchImage?: InputMaybe<ImageInput>;
  id?: InputMaybe<Scalars['ID']>;
  mainCategoryId?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['String']>;
  region: RegionInput;
  status: ArticleStatus;
  subCategoryId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  vendorIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ArticleSortType {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum ArticleStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** Autogenerated return type of ArticleUpdate */
export type ArticleUpdatePayload = {
  __typename?: 'ArticleUpdatePayload';
  article: Article;
};

export type BestSellerItem = {
  __typename?: 'BestSellerItem';
  product?: Maybe<Product>;
  totalSales?: Maybe<Scalars['Int']>;
  totalSalesCount?: Maybe<Scalars['Int']>;
};

/** The connection type for BestSellerItem. */
export type BestSellerItemConnection = {
  __typename?: 'BestSellerItemConnection';
  /** A list of edges. */
  edges: Array<BestSellerItemEdge>;
  /** A list of nodes. */
  nodes: Array<BestSellerItem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BestSellerItemEdge = {
  __typename?: 'BestSellerItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BestSellerItem;
};

export type Blog = {
  __typename?: 'Blog';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for Blog. */
export type BlogConnection = {
  __typename?: 'BlogConnection';
  /** A list of edges. */
  edges: Array<BlogEdge>;
  /** A list of nodes. */
  nodes: Array<Blog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BlogEdge = {
  __typename?: 'BlogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Blog;
};

export type Brand = RegionInterface & {
  __typename?: 'Brand';
  account?: Maybe<ShopAccount>;
  accountStatus?: Maybe<AccountStatus>;
  archived?: Maybe<Scalars['Boolean']>;
  brandLogo?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactInformation?: Maybe<Scalars['String']>;
  corporateAddress?: Maybe<Scalars['String']>;
  corporateNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  defaultCategory?: Maybe<ProductType>;
  defaultTakeRate: Scalars['Float'];
  defaultTaxRate: Scalars['Float'];
  domesticAccount?: Maybe<DomesticAccount>;
  email?: Maybe<Scalars['String']>;
  foreignCurrencyReceivableAccount?: Maybe<ForeignCurrencyReceivableAccount>;
  groups?: Maybe<Array<Group>>;
  handle?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inventoryManagementTypes?: Maybe<Array<InventoryManagementType>>;
  itemReturnAddress?: Maybe<Scalars['String']>;
  logisticsCompany?: Maybe<LogisticsCompany>;
  newInJapan: Scalars['Boolean'];
  onboardingState?: Maybe<ShopOnboardingState>;
  onboardingStep?: Maybe<ShopOnboardingStep>;
  paypal?: Maybe<Scalars['String']>;
  personInCharge?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  region: Region;
  rewardPaymentMethods?: Maybe<Array<RewardPaymentMethod>>;
  shippingConfig?: Maybe<ShippingConfig>;
  shopAccountStatusHistories?: Maybe<Array<ShopAccountStatusHistory>>;
  shopBio?: Maybe<Scalars['String']>;
  shopBioJa?: Maybe<Scalars['String']>;
  shopCoverImageUrl?: Maybe<Scalars['String']>;
  shopMemo?: Maybe<Scalars['String']>;
  shopName?: Maybe<Scalars['String']>;
  shopNameKana?: Maybe<Scalars['String']>;
  shopNote?: Maybe<Scalars['String']>;
  shopNotice?: Maybe<Scalars['String']>;
  shopType?: Maybe<ShopType>;
  superiorShop: Scalars['Boolean'];
  termsOfUseAccepted: Scalars['Boolean'];
  thirdPartyServiceCredentials?: Maybe<Array<ThirdPartyServiceCredential>>;
  vendors?: Maybe<Array<Vendor>>;
};


export type BrandTermsOfUseAcceptedArgs = {
  shopTermsOfUseId: Scalars['ID'];
};

/** The connection type for Brand. */
export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** A list of edges. */
  edges: Array<BrandEdge>;
  /** A list of nodes. */
  nodes: Array<Brand>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Brand;
};

/** Autogenerated input type of BrandInReview */
export type BrandInReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of BrandInReview */
export type BrandInReviewPayload = {
  __typename?: 'BrandInReviewPayload';
  brand: Brand;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type BrandLookContentSectionInput = {
  adminTitle: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  items: Array<BrandLookContentSectionItemInput>;
  option?: InputMaybe<Scalars['String']>;
  pageIds: Array<Scalars['ID']>;
  region: RegionInput;
  status: ContentSectionStatus;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type BrandLookContentSectionItem = {
  __typename?: 'BrandLookContentSectionItem';
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  lookImageUrl: Scalars['String'];
  platform: Platform;
  sortOrder: Scalars['Int'];
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
  vendor: Vendor;
};

export type BrandLookContentSectionItemInput = {
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  lookImage: ImageInput;
  platform: PlatformInput;
  sortOrder: Scalars['Int'];
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  vendorId: Scalars['ID'];
};

/** Autogenerated input type of BrandProductCreate */
export type BrandProductCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comingSoon?: InputMaybe<Scalars['String']>;
  gender: Scalars['String'];
  images: Array<Scalars['String']>;
  inventoryManagementType: InventoryManagement;
  itemCategory: Scalars['String'];
  itemGenres?: InputMaybe<Array<ItemGenreInput>>;
  knitting: Scalars['String'];
  materials: Array<MaterialInput>;
  originalPrice: Scalars['Int'];
  preorderDate?: InputMaybe<Scalars['String']>;
  productFeatures?: InputMaybe<Array<ProductFeatureInput>>;
  productType?: InputMaybe<ProductType>;
  seasonality?: InputMaybe<ProductSeasonalityInput>;
  shopId?: InputMaybe<Scalars['String']>;
  sizeChart: Scalars['String'];
  sizeChartFormat: Scalars['String'];
  sizeChartV2?: InputMaybe<SizeChartInput>;
  status: ProductStatus;
  takeRate: Scalars['Float'];
  taxRate: Scalars['Float'];
  titleEn: Scalars['String'];
  unisex: Scalars['Boolean'];
  variants: Array<VariantInput>;
  vendorId: Scalars['String'];
};

/** Autogenerated return type of BrandProductCreate */
export type BrandProductCreatePayload = {
  __typename?: 'BrandProductCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

/** Autogenerated input type of BrandProductUpdate */
export type BrandProductUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comingSoon?: InputMaybe<Scalars['String']>;
  gender: Scalars['String'];
  id: Scalars['ID'];
  images?: InputMaybe<Array<Scalars['String']>>;
  inventoryManagementType: InventoryManagement;
  itemCategory: Scalars['String'];
  itemGenres?: InputMaybe<Array<ItemGenreInput>>;
  knitting: Scalars['String'];
  materials: Array<MaterialInput>;
  originalPrice: Scalars['Int'];
  preorderDate?: InputMaybe<Scalars['String']>;
  productFeatures?: InputMaybe<Array<ProductFeatureInput>>;
  productType?: InputMaybe<ProductType>;
  seasonality?: InputMaybe<ProductSeasonalityInput>;
  shopId?: InputMaybe<Scalars['String']>;
  sizeChart: Scalars['String'];
  sizeChartFormat: Scalars['String'];
  sizeChartV2?: InputMaybe<SizeChartInput>;
  status: ProductStatus;
  takeRate: Scalars['Float'];
  taxRate: Scalars['Float'];
  titleEn: Scalars['String'];
  unisex: Scalars['Boolean'];
  variants: Array<VariantInput>;
  vendorId: Scalars['String'];
};

/** Autogenerated return type of BrandProductUpdate */
export type BrandProductUpdatePayload = {
  __typename?: 'BrandProductUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

/** Autogenerated input type of BrandShopSaleCreate */
export type BrandShopSaleCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  endsAt: Scalars['ISO8601DateTime'];
  shopSaleVariants: Array<ShopSaleVariantInput>;
  startsAt: Scalars['ISO8601DateTime'];
  status: ShopSaleStatus;
  title: Scalars['String'];
};

/** Autogenerated return type of BrandShopSaleCreate */
export type BrandShopSaleCreatePayload = {
  __typename?: 'BrandShopSaleCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shopSale: ShopSale;
};

/** Autogenerated input type of BrandShopSaleUpdate */
export type BrandShopSaleUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  shopSaleVariants?: InputMaybe<Array<ShopSaleVariantInput>>;
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  status?: InputMaybe<ShopSaleStatus>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of BrandShopSaleUpdate */
export type BrandShopSaleUpdatePayload = {
  __typename?: 'BrandShopSaleUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shopSale: ShopSale;
};

/** Autogenerated input type of BulkConfirmArrived */
export type BulkConfirmArrivedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of BulkConfirmArrived */
export type BulkConfirmArrivedPayload = {
  __typename?: 'BulkConfirmArrivedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  lineItems: Array<LineItem>;
};

export type Category = {
  __typename?: 'Category';
  children?: Maybe<Array<Category>>;
  handle: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  parents?: Maybe<Array<Category>>;
};

export type Color = {
  __typename?: 'Color';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ContentCollection = RegionInterface & {
  __typename?: 'ContentCollection';
  appTabType: AppTabType;
  contentSortOrder: ContentCollectionContentSortOrder;
  contentType: ContentCollectionContentType;
  createdAt: Scalars['ISO8601DateTime'];
  disjunctive: Scalars['Boolean'];
  id: Scalars['ID'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  region: Region;
  rules: Array<ContentCollectionRule>;
  sortOrder: Scalars['Int'];
  status: ContentCollectionStatus;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ContentCollectionContentSortOrder {
  BestSelling = 'BEST_SELLING',
  Latest = 'LATEST',
  PriceHighToLow = 'PRICE_HIGH_TO_LOW',
  PriceLowToHigh = 'PRICE_LOW_TO_HIGH',
  Relevance = 'RELEVANCE'
}

export enum ContentCollectionContentType {
  Article = 'ARTICLE',
  Product = 'PRODUCT'
}

/** Autogenerated return type of ContentCollectionCreate */
export type ContentCollectionCreatePayload = {
  __typename?: 'ContentCollectionCreatePayload';
  contentCollection: ContentCollection;
};

export type ContentCollectionInput = {
  appTabTypeId?: InputMaybe<Scalars['ID']>;
  contentCollectionContentType?: InputMaybe<ContentCollectionContentType>;
  contentSortOrder?: InputMaybe<ContentCollectionContentSortOrder>;
  disjunctive?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  region?: InputMaybe<RegionInput>;
  rules?: InputMaybe<Array<ContentCollectionRuleInput>>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ContentCollectionReorder */
export type ContentCollectionReorderPayload = {
  __typename?: 'ContentCollectionReorderPayload';
  contentCollection: ContentCollection;
};

export type ContentCollectionRule = {
  __typename?: 'ContentCollectionRule';
  column: ContentCollectionRuleColumn;
  condition: Scalars['String'];
  relation: ContentCollectionRuleRelation;
};

export enum ContentCollectionRuleColumn {
  BlogId = 'BLOG_ID',
  BlogTitle = 'BLOG_TITLE',
  Id = 'ID',
  Price = 'PRICE',
  Slug = 'SLUG',
  Tag = 'TAG',
  Title = 'TITLE',
  Vendor = 'VENDOR'
}

export type ContentCollectionRuleConditions = {
  __typename?: 'ContentCollectionRuleConditions';
  allowedRelations: Array<ContentCollectionRuleRelation>;
  defaultRelation: ContentCollectionRuleRelation;
  ruleType: ContentCollectionRuleColumn;
};

export type ContentCollectionRuleInput = {
  column: ContentCollectionRuleColumn;
  condition: Scalars['String'];
  relation: ContentCollectionRuleRelation;
};

export enum ContentCollectionRuleRelation {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN'
}

export enum ContentCollectionStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED'
}

/** Autogenerated return type of ContentCollectionUpdate */
export type ContentCollectionUpdatePayload = {
  __typename?: 'ContentCollectionUpdatePayload';
  contentCollection: ContentCollection;
};

export type ContentFeature = {
  __typename?: 'ContentFeature';
  areas: Array<Area>;
  category: ContentFeatureCategory;
  contentHandle: Scalars['String'];
  contentId: Scalars['ID'];
  contentType: ContentFeatureContentType;
  createdAt: Scalars['ISO8601DateTime'];
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  eyecatchImageUrl?: Maybe<Scalars['String']>;
  gender: Gender;
  id: Scalars['ID'];
  season: Season;
  shops: Array<Brand>;
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
  status: ContentFeatureStatus;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  vendors: Array<Vendor>;
};

export enum ContentFeatureCategory {
  BrandPlan = 'BRAND_PLAN',
  Campaign = 'CAMPAIGN',
  Collaboration = 'COLLABORATION',
  Feature = 'FEATURE',
  Plan = 'PLAN'
}

/** The connection type for ContentFeature. */
export type ContentFeatureConnection = {
  __typename?: 'ContentFeatureConnection';
  /** A list of edges. */
  edges: Array<ContentFeatureEdge>;
  /** A list of nodes. */
  nodes: Array<ContentFeature>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export enum ContentFeatureContentType {
  Collection = 'COLLECTION',
  ContentPage = 'CONTENT_PAGE',
  Page = 'PAGE'
}

/** Autogenerated return type of ContentFeatureCreate */
export type ContentFeatureCreatePayload = {
  __typename?: 'ContentFeatureCreatePayload';
  contentFeature: ContentFeature;
};

/** An edge in a connection. */
export type ContentFeatureEdge = {
  __typename?: 'ContentFeatureEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ContentFeature;
};

export type ContentFeatureInput = {
  areaIds?: InputMaybe<Array<Scalars['ID']>>;
  category?: InputMaybe<ContentFeatureCategory>;
  contentHandle?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['ID']>;
  contentType?: InputMaybe<ContentFeatureContentType>;
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  eyecatchImage?: InputMaybe<ImageInput>;
  gender: Gender;
  id?: InputMaybe<Scalars['ID']>;
  season?: InputMaybe<SeasonInput>;
  shopIds?: InputMaybe<Array<Scalars['ID']>>;
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  status?: InputMaybe<ContentFeatureStatus>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  vendorIds?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ContentFeatureStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

/** Autogenerated return type of ContentFeatureUpdate */
export type ContentFeatureUpdatePayload = {
  __typename?: 'ContentFeatureUpdatePayload';
  contentFeature: ContentFeature;
};

export type ContentPage = RegionInterface & {
  __typename?: 'ContentPage';
  category?: Maybe<ContentPageCategory>;
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  eyeCatchImageFile: AdminFile;
  gender?: Maybe<ContentPageGender>;
  handle: Scalars['String'];
  hideTitle: Scalars['Boolean'];
  id: Scalars['ID'];
  pageType: ContentPagePageType;
  productCollection?: Maybe<ProductCollection>;
  region: Region;
  shops?: Maybe<Array<Brand>>;
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
  status: ContentPageStatus;
  tags?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ContentPageCategory {
  BrandPlan = 'BRAND_PLAN',
  Campaign = 'CAMPAIGN',
  Collaboration = 'COLLABORATION',
  Feature = 'FEATURE',
  Plan = 'PLAN'
}

/** The connection type for ContentPage. */
export type ContentPageConnection = {
  __typename?: 'ContentPageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContentPageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ContentPage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of ContentPageCreate */
export type ContentPageCreatePayload = {
  __typename?: 'ContentPageCreatePayload';
  contentPage: ContentPage;
};

/** An edge in a connection. */
export type ContentPageEdge = {
  __typename?: 'ContentPageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ContentPage>;
};

export enum ContentPageGender {
  Men = 'MEN',
  Unisex = 'UNISEX',
  Women = 'WOMEN'
}

export type ContentPageInput = {
  category?: InputMaybe<ContentPageCategory>;
  content?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  eyeCatchImageFileId: Scalars['ID'];
  gender?: InputMaybe<ContentPageGender>;
  handle: Scalars['String'];
  hideTitle: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  pageType?: InputMaybe<ContentPagePageType>;
  productCollectionId?: InputMaybe<Scalars['ID']>;
  region: RegionInput;
  shops?: InputMaybe<Array<Scalars['ID']>>;
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  status: ContentPageStatus;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export enum ContentPagePageType {
  Collection = 'COLLECTION',
  Normal = 'NORMAL'
}

export enum ContentPageStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type ContentPageTag = {
  __typename?: 'ContentPageTag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for ContentPageTag. */
export type ContentPageTagConnection = {
  __typename?: 'ContentPageTagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContentPageTagEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ContentPageTag>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContentPageTagEdge = {
  __typename?: 'ContentPageTagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ContentPageTag>;
};

/** Autogenerated return type of ContentPageUpdate */
export type ContentPageUpdatePayload = {
  __typename?: 'ContentPageUpdatePayload';
  contentPage: ContentPage;
};

export type ContentSection = RegionInterface & {
  __typename?: 'ContentSection';
  adminTitle: Scalars['String'];
  contentSectionType: ContentSectionType;
  id: Scalars['ID'];
  items: Array<ContentSectionItem>;
  option?: Maybe<Scalars['String']>;
  pages: Array<Page>;
  region: Region;
  status: ContentSectionStatus;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ContentSectionBrandLookCreate */
export type ContentSectionBrandLookCreatePayload = {
  __typename?: 'ContentSectionBrandLookCreatePayload';
  contentSection: ContentSection;
};

/** Autogenerated return type of ContentSectionBrandLookUpdate */
export type ContentSectionBrandLookUpdatePayload = {
  __typename?: 'ContentSectionBrandLookUpdatePayload';
  contentSection: ContentSection;
};

/** The connection type for ContentSection. */
export type ContentSectionConnection = {
  __typename?: 'ContentSectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContentSectionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ContentSection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContentSectionEdge = {
  __typename?: 'ContentSectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ContentSection>;
};

export type ContentSectionItem = BrandLookContentSectionItem | MiniBannerContentSectionItem | PickupBrandContentSectionItem | ShortcutContentSectionItem | TopBannerContentSectionItem;

export type ContentSectionItemDestination = {
  __typename?: 'ContentSectionItemDestination';
  appUrl?: Maybe<Scalars['String']>;
  webUrl?: Maybe<Scalars['String']>;
};

export type ContentSectionItemDestinationInput = {
  appUrl?: InputMaybe<Scalars['String']>;
  webUrl?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ContentSectionMiniBannerCreate */
export type ContentSectionMiniBannerCreatePayload = {
  __typename?: 'ContentSectionMiniBannerCreatePayload';
  contentSection: ContentSection;
};

/** Autogenerated return type of ContentSectionMiniBannerUpdate */
export type ContentSectionMiniBannerUpdatePayload = {
  __typename?: 'ContentSectionMiniBannerUpdatePayload';
  contentSection: ContentSection;
};

/** Autogenerated return type of ContentSectionPickupBrandCreate */
export type ContentSectionPickupBrandCreatePayload = {
  __typename?: 'ContentSectionPickupBrandCreatePayload';
  contentSection: ContentSection;
};

/** Autogenerated return type of ContentSectionPickupBrandUpdate */
export type ContentSectionPickupBrandUpdatePayload = {
  __typename?: 'ContentSectionPickupBrandUpdatePayload';
  contentSection: ContentSection;
};

/** Autogenerated return type of ContentSectionShortcutCreate */
export type ContentSectionShortcutCreatePayload = {
  __typename?: 'ContentSectionShortcutCreatePayload';
  contentSection: ContentSection;
};

/** Autogenerated return type of ContentSectionShortcutUpdate */
export type ContentSectionShortcutUpdatePayload = {
  __typename?: 'ContentSectionShortcutUpdatePayload';
  contentSection: ContentSection;
};

export enum ContentSectionStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

/** Autogenerated return type of ContentSectionTopBannerCreate */
export type ContentSectionTopBannerCreatePayload = {
  __typename?: 'ContentSectionTopBannerCreatePayload';
  contentSection: ContentSection;
};

/** Autogenerated return type of ContentSectionTopBannerUpdate */
export type ContentSectionTopBannerUpdatePayload = {
  __typename?: 'ContentSectionTopBannerUpdatePayload';
  contentSection: ContentSection;
};

export type ContentSectionType = {
  __typename?: 'ContentSectionType';
  description: Scalars['String'];
  id: Scalars['ID'];
  key: ContentSectionTypeKey;
  name: Scalars['String'];
};

export enum ContentSectionTypeKey {
  BrandLook = 'BRAND_LOOK',
  MiniBanner = 'MINI_BANNER',
  PickupBrand = 'PICKUP_BRAND',
  Shortcut = 'SHORTCUT',
  TopBanner = 'TOP_BANNER'
}

export enum CountryCode {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

/** Autogenerated return type of Create */
export type CreatePayload = {
  __typename?: 'CreatePayload';
  productCollection: ProductCollection;
};

export type Customer = {
  __typename?: 'Customer';
  createdAt: Scalars['ISO8601DateTime'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pointHistories: PointHistoryConnection;
  pointSummary: CustomerPointSummary;
  state?: Maybe<CustomerState>;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type CustomerPointHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The connection type for Customer. */
export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Customer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Customer>;
};

export type CustomerPointSummary = {
  __typename?: 'CustomerPointSummary';
  addition: Scalars['Int'];
  available: Scalars['Int'];
  consumption: Scalars['Int'];
  expiration: Scalars['Int'];
};

export enum CustomerState {
  Declined = 'DECLINED',
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Invited = 'INVITED'
}

export type DomesticAccount = {
  __typename?: 'DomesticAccount';
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountType?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressZip?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  userId: Scalars['Int'];
  wiseId?: Maybe<Scalars['String']>;
};

/** The connection type for DomesticAccount. */
export type DomesticAccountConnection = {
  __typename?: 'DomesticAccountConnection';
  /** A list of edges. */
  edges: Array<DomesticAccountEdge>;
  /** A list of nodes. */
  nodes: Array<DomesticAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of DomesticAccountCreate */
export type DomesticAccountCreateInput = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  addressZip?: InputMaybe<Scalars['String']>;
  branchName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recipientType?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['ID']>;
  swiftCode?: InputMaybe<Scalars['String']>;
  usdAccountType?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of DomesticAccountCreate */
export type DomesticAccountCreatePayload = {
  __typename?: 'DomesticAccountCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  domesticAccount: DomesticAccount;
};

/** An edge in a connection. */
export type DomesticAccountEdge = {
  __typename?: 'DomesticAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DomesticAccount;
};

export type Feature = {
  __typename?: 'Feature';
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FeatureGroup = {
  __typename?: 'FeatureGroup';
  features: Array<Feature>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FileInput = {
  file: Scalars['String'];
  filename: Scalars['String'];
  mimeType: Scalars['String'];
};

export type ForeignCurrencyReceivableAccount = {
  __typename?: 'ForeignCurrencyReceivableAccount';
  accountNumber: Scalars['String'];
  address: Scalars['String'];
  beneficiaryAddress: Scalars['String'];
  beneficiaryAddressZip: Scalars['String'];
  beneficiaryName: Scalars['String'];
  beneficiaryPhoneNumber: Scalars['String'];
  branchCode?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  swiftCode: Scalars['String'];
  userId: Scalars['Int'];
};

/** The connection type for ForeignCurrencyReceivableAccount. */
export type ForeignCurrencyReceivableAccountConnection = {
  __typename?: 'ForeignCurrencyReceivableAccountConnection';
  /** A list of edges. */
  edges: Array<ForeignCurrencyReceivableAccountEdge>;
  /** A list of nodes. */
  nodes: Array<ForeignCurrencyReceivableAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of ForeignCurrencyReceivableAccountCreate */
export type ForeignCurrencyReceivableAccountCreateInput = {
  accountNumber: Scalars['String'];
  address: Scalars['String'];
  beneficiaryAddress: Scalars['String'];
  beneficiaryAddressZip: Scalars['String'];
  beneficiaryName: Scalars['String'];
  beneficiaryPhoneNumber: Scalars['String'];
  branchCode?: InputMaybe<Scalars['String']>;
  branchName?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  name: Scalars['String'];
  paypal: Scalars['String'];
  shopId?: InputMaybe<Scalars['ID']>;
  swiftCode: Scalars['String'];
};

/** Autogenerated return type of ForeignCurrencyReceivableAccountCreate */
export type ForeignCurrencyReceivableAccountCreatePayload = {
  __typename?: 'ForeignCurrencyReceivableAccountCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  foreignCurrencyReceivableAccount: ForeignCurrencyReceivableAccount;
};

/** An edge in a connection. */
export type ForeignCurrencyReceivableAccountEdge = {
  __typename?: 'ForeignCurrencyReceivableAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ForeignCurrencyReceivableAccount;
};

export enum Gender {
  Men = 'MEN',
  Unisex = 'UNISEX',
  Women = 'WOMEN'
}

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GroupInput = {
  id: Scalars['ID'];
};

export type ImageInput = {
  file: Scalars['String'];
  filename: Scalars['String'];
  mimeType?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of ImageUpload */
export type ImageUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  image: ImageInput;
};

/** Autogenerated return type of ImageUpload */
export type ImageUploadPayload = {
  __typename?: 'ImageUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
};

/** Autogenerated input type of InprocessRequest */
export type InprocessRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  trackingCompany: Scalars['String'];
  trackingNumber: Scalars['String'];
};

/** Autogenerated return type of InprocessRequest */
export type InprocessRequestPayload = {
  __typename?: 'InprocessRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  lineItem: LineItem;
};

export enum InventoryManagement {
  Consigned = 'CONSIGNED',
  Dropped = 'DROPPED',
  Stocked = 'STOCKED'
}

export type InventoryManagementType = {
  __typename?: 'InventoryManagementType';
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
};

export type InventoryManagementTypeInput = {
  id: Scalars['ID'];
};

export type InvoiceNote = {
  __typename?: 'InvoiceNote';
  adjustedPrice?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  month?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  saleDiscount?: Maybe<Scalars['Float']>;
  shop?: Maybe<Brand>;
  takeRate?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of InvoiceNoteUpdate */
export type InvoiceNoteUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  month: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  shopId: Scalars['ID'];
  takeRate?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated return type of InvoiceNoteUpdate */
export type InvoiceNoteUpdatePayload = {
  __typename?: 'InvoiceNoteUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceNote: InvoiceNote;
};

export type Issue = {
  __typename?: 'Issue';
  assigner?: Maybe<IssueActor>;
  author: IssueActor;
  body: Scalars['String'];
  category: IssueCategory;
  createdAt: Scalars['ISO8601DateTime'];
  files: Array<IssueFile>;
  followers: Array<IssueActor>;
  id: Scalars['ID'];
  lineItems: Array<LineItem>;
  messages: IssueMessageConnection;
  priority: IssuePriority;
  status: IssueStatus;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  unreadMessagesCount: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};


export type IssueMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
};

export type IssueActor = {
  __typename?: 'IssueActor';
  id: Scalars['ID'];
  kind: IssueActorKind;
  name: Scalars['String'];
};

export enum IssueActorKind {
  Brand = 'BRAND',
  Staff = 'STAFF',
  Tpl = 'TPL',
  Unknown = 'UNKNOWN'
}

export enum IssueCategory {
  ItemLost = 'ITEM_LOST',
  None = 'NONE',
  NotOnAdmin = 'NOT_ON_ADMIN',
  NoOrderNumber = 'NO_ORDER_NUMBER',
  ReshippedItem = 'RESHIPPED_ITEM',
  Sample = 'SAMPLE',
  ShippingProblem = 'SHIPPING_PROBLEM',
  WrongItem = 'WRONG_ITEM'
}

/** The connection type for Issue. */
export type IssueConnection = {
  __typename?: 'IssueConnection';
  /** A list of edges. */
  edges: Array<IssueEdge>;
  /** A list of nodes. */
  nodes: Array<Issue>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of IssueCreateFile */
export type IssueCreateFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  files: Array<ImageInput>;
  id: Scalars['ID'];
};

/** Autogenerated return type of IssueCreateFile */
export type IssueCreateFilePayload = {
  __typename?: 'IssueCreateFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  issueFiles: Array<IssueFile>;
};

/** Autogenerated input type of IssueCreate */
export type IssueCreateInput = {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<ImageInput>>;
  lineItemIds?: InputMaybe<Array<Scalars['ID']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

/** Autogenerated return type of IssueCreate */
export type IssueCreatePayload = {
  __typename?: 'IssueCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  issue: Issue;
};

/** Autogenerated input type of IssueDelete */
export type IssueDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of IssueDelete */
export type IssueDeletePayload = {
  __typename?: 'IssueDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedIssueId: Scalars['Int'];
};

/** An edge in a connection. */
export type IssueEdge = {
  __typename?: 'IssueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Issue;
};

export type IssueFile = {
  __typename?: 'IssueFile';
  follower: IssueActor;
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type IssueFollowerInput = {
  id: Scalars['ID'];
  kind: IssueActorKind;
};

export type IssueMessage = {
  __typename?: 'IssueMessage';
  author: IssueActor;
  body: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for IssueMessage. */
export type IssueMessageConnection = {
  __typename?: 'IssueMessageConnection';
  /** A list of edges. */
  edges: Array<IssueMessageEdge>;
  /** A list of nodes. */
  nodes: Array<IssueMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of IssueMessageCreate */
export type IssueMessageCreateInput = {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  issueId: Scalars['ID'];
};

/** Autogenerated return type of IssueMessageCreate */
export type IssueMessageCreatePayload = {
  __typename?: 'IssueMessageCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: IssueMessage;
};

/** An edge in a connection. */
export type IssueMessageEdge = {
  __typename?: 'IssueMessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IssueMessage;
};

/** Autogenerated input type of IssueMessagesMarkAllAsRead */
export type IssueMessagesMarkAllAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  issueId: Scalars['ID'];
};

/** Autogenerated return type of IssueMessagesMarkAllAsRead */
export type IssueMessagesMarkAllAsReadPayload = {
  __typename?: 'IssueMessagesMarkAllAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum IssuePriority {
  High = 'HIGH',
  Low = 'LOW',
  Middle = 'MIDDLE',
  None = 'NONE',
  Urgent = 'URGENT'
}

export enum IssueStatus {
  Done = 'DONE',
  NotYet = 'NOT_YET'
}

/** Autogenerated input type of IssueUpdate */
export type IssueUpdateInput = {
  assignerId?: InputMaybe<Scalars['ID']>;
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<IssueCategory>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<Array<IssueFollowerInput>>;
  id: Scalars['ID'];
  lineItemIds?: InputMaybe<Array<Scalars['ID']>>;
  priority?: InputMaybe<IssuePriority>;
  status?: InputMaybe<IssueStatus>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of IssueUpdate */
export type IssueUpdatePayload = {
  __typename?: 'IssueUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  issue: Issue;
};

export type ItemCategory = {
  __typename?: 'ItemCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  seasonal: Scalars['Boolean'];
  sizeEditable: Scalars['Boolean'];
  sizes: Array<Size>;
};

export type ItemGenre = {
  __typename?: 'ItemGenre';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameEn: Scalars['String'];
};

export type ItemGenreInput = {
  id: Scalars['ID'];
};

export type Knitting = {
  __typename?: 'Knitting';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Language = {
  __typename?: 'Language';
  id: Scalars['ID'];
  name: Scalars['String'];
  originName: Scalars['String'];
};

export type LineItem = {
  __typename?: 'LineItem';
  availableQuantity?: Maybe<Scalars['Int']>;
  brandId?: Maybe<Scalars['ID']>;
  commentForBrand?: Maybe<Scalars['String']>;
  commentForTpl?: Maybe<Scalars['String']>;
  compareAtPrice?: Maybe<Scalars['Int']>;
  confirmedStatus?: Maybe<LineItemConfirmedStatusType>;
  createdAt: Scalars['ISO8601DateTime'];
  customerName?: Maybe<Scalars['String']>;
  fulfilledAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputPrice?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['ID']>;
  lineItemConfirmHistories?: Maybe<Array<LineItemConfirmHistory>>;
  location?: Maybe<Location>;
  orderId?: Maybe<Scalars['ID']>;
  orderNumber?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Scalars['Int']>;
  preTrackingCompany?: Maybe<Scalars['String']>;
  preTrackingNumber?: Maybe<Scalars['String']>;
  preorderDate?: Maybe<Scalars['ISO8601DateTime']>;
  price?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']>;
  productName?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  sentToTpl?: Maybe<Scalars['Boolean']>;
  shippingAddressCountry?: Maybe<Scalars['String']>;
  shippingStatus?: Maybe<LineItemShippingStatus>;
  shop?: Maybe<Brand>;
  shopifyProduct?: Maybe<ShopifyProduct>;
  shopifyProductVariant?: Maybe<ShopifyProductVariant>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transferred?: Maybe<Scalars['Boolean']>;
  variantId?: Maybe<Scalars['ID']>;
  variantTitle?: Maybe<Scalars['String']>;
};

export enum LineItemCancelStatusType {
  Canceled = 'CANCELED',
  NotCanceled = 'NOT_CANCELED',
  PartiallyCanceled = 'PARTIALLY_CANCELED'
}

export type LineItemConfirmHistory = {
  __typename?: 'LineItemConfirmHistory';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  lineItemId: Scalars['ID'];
  status: LineItemConfirmHistoryStatus;
};

/** Autogenerated input type of LineItemConfirmHistoryBulkCreate */
export type LineItemConfirmHistoryBulkCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemIds: Array<Scalars['ID']>;
  status: LineItemConfirmHistoryStatus;
};

/** Autogenerated return type of LineItemConfirmHistoryBulkCreate */
export type LineItemConfirmHistoryBulkCreatePayload = {
  __typename?: 'LineItemConfirmHistoryBulkCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  lineItemConfirmHistories: Array<LineItemConfirmHistory>;
};

/** Autogenerated input type of LineItemConfirmHistoryCreate */
export type LineItemConfirmHistoryCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  lineItemId: Scalars['ID'];
  status: LineItemConfirmHistoryStatus;
};

/** Autogenerated return type of LineItemConfirmHistoryCreate */
export type LineItemConfirmHistoryCreatePayload = {
  __typename?: 'LineItemConfirmHistoryCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  lineItemConfirmHistory: LineItemConfirmHistory;
};

export enum LineItemConfirmHistoryStatus {
  Confirmed = 'CONFIRMED',
  Unconfirmed = 'UNCONFIRMED'
}

export enum LineItemConfirmedStatusType {
  Confirmed = 'CONFIRMED',
  Unconfirmed = 'UNCONFIRMED'
}

/** The connection type for LineItem. */
export type LineItemConnection = {
  __typename?: 'LineItemConnection';
  /** A list of edges. */
  edges: Array<LineItemEdge>;
  /** A list of nodes. */
  nodes: Array<LineItem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LineItemEdge = {
  __typename?: 'LineItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LineItem;
};

/** Autogenerated input type of LineItemImportBulkInProcessListRequest */
export type LineItemImportBulkInProcessListRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  file: FileInput;
};

/** Autogenerated return type of LineItemImportBulkInProcessListRequest */
export type LineItemImportBulkInProcessListRequestPayload = {
  __typename?: 'LineItemImportBulkInProcessListRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  lineItems: Array<LineItem>;
};

export enum LineItemShippingStatus {
  Arrived = 'ARRIVED',
  Fulfilled = 'FULFILLED',
  InProcess = 'IN_PROCESS',
  Pending = 'PENDING'
}

export type LineItemShippingStatusInput = {
  id: Scalars['ID'];
  status: LineItemShippingStatus;
};

/** Autogenerated input type of LineItemUpdate */
export type LineItemUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentForBrand: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of LineItemUpdate */
export type LineItemUpdatePayload = {
  __typename?: 'LineItemUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  lineItem: LineItem;
};

/** Autogenerated input type of LineItemsBulkChangeShippingStatus */
export type LineItemsBulkChangeShippingStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  statuses: Array<LineItemShippingStatusInput>;
};

/** Autogenerated return type of LineItemsBulkChangeShippingStatus */
export type LineItemsBulkChangeShippingStatusPayload = {
  __typename?: 'LineItemsBulkChangeShippingStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  lineItems: Array<LineItem>;
};

export type LineItemsSalesReport = {
  __typename?: 'LineItemsSalesReport';
  date?: Maybe<Scalars['ISO8601Date']>;
  totalSales?: Maybe<Scalars['Int']>;
};

/** The connection type for LineItemsSalesReport. */
export type LineItemsSalesReportConnection = {
  __typename?: 'LineItemsSalesReportConnection';
  /** A list of edges. */
  edges: Array<LineItemsSalesReportEdge>;
  /** A list of nodes. */
  nodes: Array<LineItemsSalesReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LineItemsSalesReportEdge = {
  __typename?: 'LineItemsSalesReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LineItemsSalesReport;
};

export type LineItemsTabsCount = {
  __typename?: 'LineItemsTabsCount';
  arrived: Scalars['Int'];
  cancelled: Scalars['Int'];
  fulfilled: Scalars['Int'];
  inProcess: Scalars['Int'];
  pendingConfirmed: Scalars['Int'];
  pendingUnconfirmed: Scalars['Int'];
};

export type Location = {
  __typename?: 'Location';
  consigned?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  dropped?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  stocked?: Maybe<Scalars['Boolean']>;
};

export type LogisticsCompany = {
  __typename?: 'LogisticsCompany';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of LogisticsCompanyIssueCreate */
export type LogisticsCompanyIssueCreateInput = {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<ImageInput>>;
  lineItemIds?: InputMaybe<Array<Scalars['ID']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

/** Autogenerated return type of LogisticsCompanyIssueCreate */
export type LogisticsCompanyIssueCreatePayload = {
  __typename?: 'LogisticsCompanyIssueCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  issue: Issue;
};

/** Autogenerated input type of LogisticsCompanyIssueMessageCreate */
export type LogisticsCompanyIssueMessageCreateInput = {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  issueId: Scalars['ID'];
};

/** Autogenerated return type of LogisticsCompanyIssueMessageCreate */
export type LogisticsCompanyIssueMessageCreatePayload = {
  __typename?: 'LogisticsCompanyIssueMessageCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: IssueMessage;
};

/** Autogenerated input type of LogisticsCompanyIssueMessagesMarkAllAsRead */
export type LogisticsCompanyIssueMessagesMarkAllAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  issueId: Scalars['ID'];
};

/** Autogenerated return type of LogisticsCompanyIssueMessagesMarkAllAsRead */
export type LogisticsCompanyIssueMessagesMarkAllAsReadPayload = {
  __typename?: 'LogisticsCompanyIssueMessagesMarkAllAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of LogisticsCompanyIssueUpdate */
export type LogisticsCompanyIssueUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of LogisticsCompanyIssueUpdate */
export type LogisticsCompanyIssueUpdatePayload = {
  __typename?: 'LogisticsCompanyIssueUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  issue: Issue;
};

export type Material = {
  __typename?: 'Material';
  percent: Scalars['String'];
  textile: Scalars['String'];
};

export type MaterialAttribute = {
  __typename?: 'MaterialAttribute';
  percent: Array<Scalars['String']>;
  textile: Array<Scalars['String']>;
};

export type MaterialInput = {
  percent: Scalars['String'];
  textile: Scalars['String'];
};

export type MiniBannerContentSectionInput = {
  adminTitle: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  items: Array<MiniBannerContentSectionItemInput>;
  option?: InputMaybe<Scalars['String']>;
  pageIds: Array<Scalars['ID']>;
  region: RegionInput;
  status: ContentSectionStatus;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type MiniBannerContentSectionItem = {
  __typename?: 'MiniBannerContentSectionItem';
  destination: ContentSectionItemDestination;
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  eyecatchImageUrl: Scalars['String'];
  id: Scalars['ID'];
  platform: Platform;
  sortOrder: Scalars['Int'];
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type MiniBannerContentSectionItemInput = {
  destination: ContentSectionItemDestinationInput;
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  eyecatchImage: ImageInput;
  id?: InputMaybe<Scalars['ID']>;
  platform: PlatformInput;
  sortOrder: Scalars['Int'];
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type MoveInput = {
  id: Scalars['ID'];
  newPosition: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adjustmentCreate?: Maybe<AdjustmentCreatePayload>;
  adjustmentsBulkDelete?: Maybe<AdjustmentsBulkDeletePayload>;
  adminCustomerNotificationCreate?: Maybe<AdminCustomerNotificationCreatePayload>;
  adminFileCreate?: Maybe<AdminFileCreatePayload>;
  adminFileUpdate?: Maybe<AdminFileUpdatePayload>;
  areaReorder?: Maybe<ReorderPayload>;
  areaUpdate?: Maybe<AreaUpdatePayload>;
  articleCreate?: Maybe<ArticleCreatePayload>;
  articleImageUpload?: Maybe<ImageUploadPayload>;
  articleUpdate?: Maybe<ArticleUpdatePayload>;
  brandInReview?: Maybe<BrandInReviewPayload>;
  brandLookContentSectionCreate?: Maybe<ContentSectionBrandLookCreatePayload>;
  brandLookContentSectionUpdate?: Maybe<ContentSectionBrandLookUpdatePayload>;
  brandProductCreate?: Maybe<BrandProductCreatePayload>;
  brandProductUpdate?: Maybe<BrandProductUpdatePayload>;
  brandShopSaleCreate?: Maybe<BrandShopSaleCreatePayload>;
  brandShopSaleUpdate?: Maybe<BrandShopSaleUpdatePayload>;
  contentCollectionCreate?: Maybe<ContentCollectionCreatePayload>;
  contentCollectionReorder?: Maybe<ContentCollectionReorderPayload>;
  contentCollectionUpdate?: Maybe<ContentCollectionUpdatePayload>;
  contentFeatureCreate?: Maybe<ContentFeatureCreatePayload>;
  contentFeatureUpdate?: Maybe<ContentFeatureUpdatePayload>;
  contentPageCreate?: Maybe<ContentPageCreatePayload>;
  contentPageUpdate?: Maybe<ContentPageUpdatePayload>;
  domesticAccountCreate?: Maybe<DomesticAccountCreatePayload>;
  foreignCurrencyReceivableAccountCreate?: Maybe<ForeignCurrencyReceivableAccountCreatePayload>;
  invoiceNoteUpdate?: Maybe<InvoiceNoteUpdatePayload>;
  issueCreate?: Maybe<IssueCreatePayload>;
  issueCreateFiles?: Maybe<IssueCreateFilePayload>;
  issueDelete?: Maybe<IssueDeletePayload>;
  issueMessageCreate?: Maybe<IssueMessageCreatePayload>;
  issueMessagesMarkAllAsRead?: Maybe<IssueMessagesMarkAllAsReadPayload>;
  issueUpdate?: Maybe<IssueUpdatePayload>;
  lineItemConfirmHistoriesBulkCreate?: Maybe<LineItemConfirmHistoryBulkCreatePayload>;
  lineItemConfirmHistoryCreate?: Maybe<LineItemConfirmHistoryCreatePayload>;
  lineItemsImportBulkInProcessListRequest?: Maybe<LineItemImportBulkInProcessListRequestPayload>;
  lineItemsInprocessRequest?: Maybe<InprocessRequestPayload>;
  lineItemsUpdate?: Maybe<LineItemUpdatePayload>;
  logisticsCompaniesLineItemsBulkChangeShippingStatus?: Maybe<LineItemsBulkChangeShippingStatusPayload>;
  logisticsCompaniesLineItemsBulkConfirmArrived?: Maybe<BulkConfirmArrivedPayload>;
  logisticsCompanyIssueCreate?: Maybe<LogisticsCompanyIssueCreatePayload>;
  logisticsCompanyIssueMessageCreate?: Maybe<LogisticsCompanyIssueMessageCreatePayload>;
  logisticsCompanyIssueMessagesMarkAllAsRead?: Maybe<LogisticsCompanyIssueMessagesMarkAllAsReadPayload>;
  logisticsCompanyIssueUpdate?: Maybe<LogisticsCompanyIssueUpdatePayload>;
  miniBannerContentSectionCreate?: Maybe<ContentSectionMiniBannerCreatePayload>;
  miniBannerContentSectionUpdate?: Maybe<ContentSectionMiniBannerUpdatePayload>;
  packingListBulkInventoryAdjustQuantity?: Maybe<PackingListBulkInventoryAdjustQuantityPayload>;
  packingListCreate?: Maybe<PackingListCreatePayload>;
  packingListInspecting?: Maybe<PackingListInspectingPayload>;
  packingListInventoryRegistered?: Maybe<PackingListInventoryRegisteredPayload>;
  packingListPackageItemInventoryRegistering?: Maybe<PackingListPackageItemInventoryRegisteringPayload>;
  packingListPackageItemPriceSet?: Maybe<PackingListPackageItemPriceSetPayload>;
  packingListShipped?: Maybe<PackingListShippedPayload>;
  pickupBrandContentSectionCreate?: Maybe<ContentSectionPickupBrandCreatePayload>;
  pickupBrandContentSectionUpdate?: Maybe<ContentSectionPickupBrandUpdatePayload>;
  pointAddByAdmin?: Maybe<PointAddByAdminPayload>;
  pointSubtractByAdmin?: Maybe<PointSubtractByAdminPayload>;
  productAppendImages?: Maybe<ProductAppendImagesPayload>;
  productArchived?: Maybe<ProductArchivedPayload>;
  productBulkChangeStatus?: Maybe<ProductBulkChangeStatusPayload>;
  productCollectionCreate?: Maybe<CreatePayload>;
  productCollectionUpdate?: Maybe<UpdatePayload>;
  productComingSoonDateTimeUpdate?: Maybe<ProductComingSoonDateTimeUpdatePayload>;
  productCouponCreate?: Maybe<ProductCouponCreatePayload>;
  productCouponUpdate?: Maybe<ProductCouponUpdatePayload>;
  productCreate?: Maybe<ProductCreatePayload>;
  productDeleteImages?: Maybe<ProductDeleteImagesPayload>;
  productDraft?: Maybe<ProductDraftPayload>;
  productImageUpload?: Maybe<ProductImageUploadPayload>;
  productPreOrderDateUpdate?: Maybe<ProductPreOrderDateUpdatePayload>;
  productReorderImages?: Maybe<ProductReorderImagesPayload>;
  productUnarchived?: Maybe<ProductUnarchivedPayload>;
  productUpdate?: Maybe<ProductUpdatePayload>;
  productVariantUpdateQuantity?: Maybe<ProductVariantUpdateQuantityPayload>;
  replannedShippingCreate?: Maybe<ReplannedShippingCreatePayload>;
  salesReportFix?: Maybe<SalesReportFixPayload>;
  salesReportUnFix?: Maybe<SalesReportUnFixPayload>;
  salesReportsBulkFix?: Maybe<SalesReportsBulkFixPayload>;
  salesReportsExportRequestCreate?: Maybe<SalesReportsExportRequestCreatePayload>;
  salesReportsRefresh?: Maybe<SalesReportsRefreshPayload>;
  searchDictionaryAddSynonym?: Maybe<SearchDictionaryAddSynonymPayload>;
  searchDictionaryCreate?: Maybe<SearchDictionaryCreatePayload>;
  searchDictionaryDelete?: Maybe<SearchDictionaryDeletePayload>;
  searchDictionaryRemoveSynonym?: Maybe<SearchDictionaryRemoveSynonymPayload>;
  searchDictionaryUpdate?: Maybe<SearchDictionaryUpdatePayload>;
  searchSynonymCreate?: Maybe<SearchSynonymCreatePayload>;
  searchSynonymDelete?: Maybe<SearchSynonymDeletePayload>;
  searchSynonymUpdate?: Maybe<SearchSynonymUpdatePayload>;
  searchTagAddProduct?: Maybe<SearchTagAddProductPayload>;
  searchTagBulkAddProducts?: Maybe<SearchTagBulkAddProductPayload>;
  searchTagCreate?: Maybe<SearchTagCreatePayload>;
  searchTagDelete?: Maybe<SearchTagDeletePayload>;
  searchTagRemoveProduct?: Maybe<SearchTagRemoveProductPayload>;
  searchTagUpdate?: Maybe<SearchTagUpdatePayload>;
  shippingConfigCreate?: Maybe<ShippingConfigCreatePayload>;
  shippingConfigUpdate?: Maybe<ShippingConfigUpdatePayload>;
  shopAccountStatusUpdate?: Maybe<ShopAccountStatusUpdatePayload>;
  shopActivate?: Maybe<ShopActivatePayload>;
  shopInformationUpdate?: Maybe<ShopInformationUpdatePayload>;
  shopIssueCreate?: Maybe<ShopIssueCreatePayload>;
  shopIssueMessageCreate?: Maybe<ShopIssueMessageCreatePayload>;
  shopIssueMessagesMarkAllAsRead?: Maybe<ShopIssueMessagesMarkAllAsReadPayload>;
  shopIssueUpdate?: Maybe<ShopIssueUpdatePayload>;
  shopProductVariantUpdateQuantity?: Maybe<ShopProductVariantUpdateQuantityPayload>;
  shopSaleConfirm?: Maybe<ShopSaleConfirmPayload>;
  shopSaleForceStop?: Maybe<ShopSaleForceStopPayload>;
  shopSaleReapply?: Maybe<ShopSaleReapplyPayload>;
  shopSaleStatusUpdate?: Maybe<ShopSaleStatusUpdatePayload>;
  shopSaleUpdate?: Maybe<ShopSaleUpdatePayload>;
  shopSellmateIntegrationRequestCreate?: Maybe<ShopSellmateIntegrationRequestCreatePayload>;
  shopShopTermsOfUseAccept?: Maybe<ShopShopTermsOfUseAcceptPayload>;
  shopTermsOfUseCreate?: Maybe<ShopTermsOfUseCreatePayload>;
  shopTermsOfUsePublish?: Maybe<ShopTermsOfUsePublishPayload>;
  shopTermsOfUseUpdate?: Maybe<ShopTermsOfUseUpdatePayload>;
  shopThirdPartyServiceCredentialCreate?: Maybe<ShopThirdPartyServiceCredentialCreatePayload>;
  shopUpdate?: Maybe<ShopUpdatePayload>;
  shopVendorCreate?: Maybe<ShopVendorCreatePayload>;
  shopVendorDelete?: Maybe<ShopVendorDeletePayload>;
  shopVendorSizeCreate?: Maybe<ShopVendorSizeCreatePayload>;
  shopVendorSizeDelete?: Maybe<ShopVendorSizeDeletePayload>;
  shopVendorSizeUpdate?: Maybe<ShopVendorSizeUpdatePayload>;
  shortcutContentSectionCreate?: Maybe<ContentSectionShortcutCreatePayload>;
  shortcutContentSectionUpdate?: Maybe<ContentSectionShortcutUpdatePayload>;
  stylingCreate?: Maybe<StylingCreatePayload>;
  stylingDelete?: Maybe<StylingDeletePayload>;
  stylingModelCreate?: Maybe<StylingModelCreatePayload>;
  stylingModelUpdate?: Maybe<StylingModelUpdatePayload>;
  stylingUpdate?: Maybe<StylingUpdatePayload>;
  topBannerContentSectionCreate?: Maybe<ContentSectionTopBannerCreatePayload>;
  topBannerContentSectionUpdate?: Maybe<ContentSectionTopBannerUpdatePayload>;
  vendorBannerCreate?: Maybe<VendorBannerCreatePayload>;
  vendorBannerDelete?: Maybe<VendorBannerDeletePayload>;
  vendorBannerUpdate?: Maybe<VendorBannerUpdatePayload>;
  vendorUpdate?: Maybe<VendorUpdatePayload>;
};


export type MutationAdjustmentCreateArgs = {
  input: AdjustmentCreateInput;
};


export type MutationAdjustmentsBulkDeleteArgs = {
  input: AdjustmentsBulkDeleteInput;
};


export type MutationAdminCustomerNotificationCreateArgs = {
  input: AdminCustomerNotificationCreateInput;
};


export type MutationAdminFileCreateArgs = {
  input: AdminFileCreateInput;
};


export type MutationAdminFileUpdateArgs = {
  input: AdminFileUpdateInput;
};


export type MutationAreaReorderArgs = {
  move: MoveInput;
};


export type MutationAreaUpdateArgs = {
  input: AreaUpdateInput;
};


export type MutationArticleCreateArgs = {
  input: ArticleInput;
};


export type MutationArticleImageUploadArgs = {
  input: ImageUploadInput;
};


export type MutationArticleUpdateArgs = {
  input: ArticleInput;
};


export type MutationBrandInReviewArgs = {
  input: BrandInReviewInput;
};


export type MutationBrandLookContentSectionCreateArgs = {
  input: BrandLookContentSectionInput;
};


export type MutationBrandLookContentSectionUpdateArgs = {
  input: BrandLookContentSectionInput;
};


export type MutationBrandProductCreateArgs = {
  input: BrandProductCreateInput;
};


export type MutationBrandProductUpdateArgs = {
  input: BrandProductUpdateInput;
};


export type MutationBrandShopSaleCreateArgs = {
  input: BrandShopSaleCreateInput;
};


export type MutationBrandShopSaleUpdateArgs = {
  input: BrandShopSaleUpdateInput;
};


export type MutationContentCollectionCreateArgs = {
  input: ContentCollectionInput;
};


export type MutationContentCollectionReorderArgs = {
  move: MoveInput;
};


export type MutationContentCollectionUpdateArgs = {
  input: ContentCollectionInput;
};


export type MutationContentFeatureCreateArgs = {
  input: ContentFeatureInput;
};


export type MutationContentFeatureUpdateArgs = {
  input: ContentFeatureInput;
};


export type MutationContentPageCreateArgs = {
  input: ContentPageInput;
};


export type MutationContentPageUpdateArgs = {
  input: ContentPageInput;
};


export type MutationDomesticAccountCreateArgs = {
  input: DomesticAccountCreateInput;
};


export type MutationForeignCurrencyReceivableAccountCreateArgs = {
  input: ForeignCurrencyReceivableAccountCreateInput;
};


export type MutationInvoiceNoteUpdateArgs = {
  input: InvoiceNoteUpdateInput;
};


export type MutationIssueCreateArgs = {
  input: IssueCreateInput;
};


export type MutationIssueCreateFilesArgs = {
  input: IssueCreateFileInput;
};


export type MutationIssueDeleteArgs = {
  input: IssueDeleteInput;
};


export type MutationIssueMessageCreateArgs = {
  input: IssueMessageCreateInput;
};


export type MutationIssueMessagesMarkAllAsReadArgs = {
  input: IssueMessagesMarkAllAsReadInput;
};


export type MutationIssueUpdateArgs = {
  input: IssueUpdateInput;
};


export type MutationLineItemConfirmHistoriesBulkCreateArgs = {
  input: LineItemConfirmHistoryBulkCreateInput;
};


export type MutationLineItemConfirmHistoryCreateArgs = {
  input: LineItemConfirmHistoryCreateInput;
};


export type MutationLineItemsImportBulkInProcessListRequestArgs = {
  input: LineItemImportBulkInProcessListRequestInput;
};


export type MutationLineItemsInprocessRequestArgs = {
  input: InprocessRequestInput;
};


export type MutationLineItemsUpdateArgs = {
  input: LineItemUpdateInput;
};


export type MutationLogisticsCompaniesLineItemsBulkChangeShippingStatusArgs = {
  input: LineItemsBulkChangeShippingStatusInput;
};


export type MutationLogisticsCompaniesLineItemsBulkConfirmArrivedArgs = {
  input: BulkConfirmArrivedInput;
};


export type MutationLogisticsCompanyIssueCreateArgs = {
  input: LogisticsCompanyIssueCreateInput;
};


export type MutationLogisticsCompanyIssueMessageCreateArgs = {
  input: LogisticsCompanyIssueMessageCreateInput;
};


export type MutationLogisticsCompanyIssueMessagesMarkAllAsReadArgs = {
  input: LogisticsCompanyIssueMessagesMarkAllAsReadInput;
};


export type MutationLogisticsCompanyIssueUpdateArgs = {
  input: LogisticsCompanyIssueUpdateInput;
};


export type MutationMiniBannerContentSectionCreateArgs = {
  input: MiniBannerContentSectionInput;
};


export type MutationMiniBannerContentSectionUpdateArgs = {
  input: MiniBannerContentSectionInput;
};


export type MutationPackingListBulkInventoryAdjustQuantityArgs = {
  input: PackingListBulkInventoryAdjustQuantityInput;
};


export type MutationPackingListCreateArgs = {
  input: PackingListCreateInput;
};


export type MutationPackingListInspectingArgs = {
  input: PackingListInspectingInput;
};


export type MutationPackingListInventoryRegisteredArgs = {
  input: PackingListInventoryRegisteredInput;
};


export type MutationPackingListPackageItemInventoryRegisteringArgs = {
  input: PackingListPackageItemInventoryRegisteringInput;
};


export type MutationPackingListPackageItemPriceSetArgs = {
  input: PackingListPackageItemPriceSetInput;
};


export type MutationPackingListShippedArgs = {
  input: PackingListShippedInput;
};


export type MutationPickupBrandContentSectionCreateArgs = {
  input: PickupBrandContentSectionInput;
};


export type MutationPickupBrandContentSectionUpdateArgs = {
  input: PickupBrandContentSectionInput;
};


export type MutationPointAddByAdminArgs = {
  input: PointAddByAdminInput;
};


export type MutationPointSubtractByAdminArgs = {
  input: PointSubtractByAdminInput;
};


export type MutationProductAppendImagesArgs = {
  input: ProductAppendImagesInput;
};


export type MutationProductArchivedArgs = {
  input: ProductArchivedInput;
};


export type MutationProductBulkChangeStatusArgs = {
  statuses: Array<ProductStatusInput>;
};


export type MutationProductCollectionCreateArgs = {
  input: ProductCollectionInput;
};


export type MutationProductCollectionUpdateArgs = {
  input: ProductCollectionInput;
};


export type MutationProductComingSoonDateTimeUpdateArgs = {
  input: ProductComingSoonDateTimeUpdateInput;
};


export type MutationProductCouponCreateArgs = {
  input: ProductCouponInput;
};


export type MutationProductCouponUpdateArgs = {
  input: ProductCouponInput;
};


export type MutationProductCreateArgs = {
  input: ProductCreateInput;
};


export type MutationProductDeleteImagesArgs = {
  input: ProductDeleteImagesInput;
};


export type MutationProductDraftArgs = {
  input: ProductDraftInput;
};


export type MutationProductImageUploadArgs = {
  input: ProductImageUploadInput;
};


export type MutationProductPreOrderDateUpdateArgs = {
  input: ProductPreOrderDateUpdateInput;
};


export type MutationProductReorderImagesArgs = {
  input: ProductReorderImagesInput;
};


export type MutationProductUnarchivedArgs = {
  input: ProductUnarchivedInput;
};


export type MutationProductUpdateArgs = {
  input: ProductUpdateInput;
};


export type MutationProductVariantUpdateQuantityArgs = {
  input: ProductVariantUpdateQuantityInput;
};


export type MutationReplannedShippingCreateArgs = {
  input: ReplannedShippingCreateInput;
};


export type MutationSalesReportFixArgs = {
  input: SalesReportFixInput;
};


export type MutationSalesReportUnFixArgs = {
  input: SalesReportUnFixInput;
};


export type MutationSalesReportsBulkFixArgs = {
  input: SalesReportsBulkFixInput;
};


export type MutationSalesReportsExportRequestCreateArgs = {
  input: SalesReportsExportRequestCreateInput;
};


export type MutationSalesReportsRefreshArgs = {
  input: SalesReportsRefreshInput;
};


export type MutationSearchDictionaryAddSynonymArgs = {
  dictionaryId: Scalars['ID'];
  synonymId: Scalars['ID'];
};


export type MutationSearchDictionaryCreateArgs = {
  input: SearchDictionaryInput;
};


export type MutationSearchDictionaryDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationSearchDictionaryRemoveSynonymArgs = {
  dictionaryId: Scalars['ID'];
  synonymId: Scalars['ID'];
};


export type MutationSearchDictionaryUpdateArgs = {
  input: SearchDictionaryInput;
};


export type MutationSearchSynonymCreateArgs = {
  input: SearchSynonymInput;
};


export type MutationSearchSynonymDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationSearchSynonymUpdateArgs = {
  input: SearchSynonymInput;
};


export type MutationSearchTagAddProductArgs = {
  productId: Scalars['ID'];
  tagId: Scalars['ID'];
};


export type MutationSearchTagBulkAddProductsArgs = {
  productIds: Array<Scalars['ID']>;
  tagId: Scalars['ID'];
};


export type MutationSearchTagCreateArgs = {
  name: Scalars['String'];
};


export type MutationSearchTagDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationSearchTagRemoveProductArgs = {
  productId: Scalars['ID'];
  tagId: Scalars['ID'];
};


export type MutationSearchTagUpdateArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationShippingConfigCreateArgs = {
  input: ShippingConfigCreateInput;
};


export type MutationShippingConfigUpdateArgs = {
  input: ShippingConfigUpdateInput;
};


export type MutationShopAccountStatusUpdateArgs = {
  input: ShopAccountStatusUpdateInput;
};


export type MutationShopActivateArgs = {
  input: ShopActivateInput;
};


export type MutationShopInformationUpdateArgs = {
  input: ShopInformationUpdateInput;
};


export type MutationShopIssueCreateArgs = {
  input: ShopIssueCreateInput;
};


export type MutationShopIssueMessageCreateArgs = {
  input: ShopIssueMessageCreateInput;
};


export type MutationShopIssueMessagesMarkAllAsReadArgs = {
  input: ShopIssueMessagesMarkAllAsReadInput;
};


export type MutationShopIssueUpdateArgs = {
  input: ShopIssueUpdateInput;
};


export type MutationShopProductVariantUpdateQuantityArgs = {
  input: ShopProductVariantUpdateQuantityInput;
};


export type MutationShopSaleConfirmArgs = {
  input: ShopSaleConfirmInput;
};


export type MutationShopSaleForceStopArgs = {
  input: ShopSaleForceStopInput;
};


export type MutationShopSaleReapplyArgs = {
  input: ShopSaleReapplyInput;
};


export type MutationShopSaleStatusUpdateArgs = {
  input: ShopSaleStatusUpdateInput;
};


export type MutationShopSaleUpdateArgs = {
  input: ShopSaleUpdateInput;
};


export type MutationShopSellmateIntegrationRequestCreateArgs = {
  input: ShopSellmateIntegrationRequestCreateInput;
};


export type MutationShopShopTermsOfUseAcceptArgs = {
  input: ShopShopTermsOfUseAcceptInput;
};


export type MutationShopTermsOfUseCreateArgs = {
  input: ShopTermsOfUseInput;
};


export type MutationShopTermsOfUsePublishArgs = {
  input: ShopTermsOfUsePublishInput;
};


export type MutationShopTermsOfUseUpdateArgs = {
  input: ShopTermsOfUseInput;
};


export type MutationShopThirdPartyServiceCredentialCreateArgs = {
  input: ShopThirdPartyServiceCredentialCreateInput;
};


export type MutationShopUpdateArgs = {
  input: ShopInput;
};


export type MutationShopVendorCreateArgs = {
  input: ShopVendorCreateInput;
};


export type MutationShopVendorDeleteArgs = {
  input: ShopVendorDeleteInput;
};


export type MutationShopVendorSizeCreateArgs = {
  input: ShopVendorSizeCreateInput;
};


export type MutationShopVendorSizeDeleteArgs = {
  input: ShopVendorSizeDeleteInput;
};


export type MutationShopVendorSizeUpdateArgs = {
  input: ShopVendorSizeUpdateInput;
};


export type MutationShortcutContentSectionCreateArgs = {
  input: ShortcutContentSectionInput;
};


export type MutationShortcutContentSectionUpdateArgs = {
  input: ShortcutContentSectionInput;
};


export type MutationStylingCreateArgs = {
  input: StylingCreateInput;
};


export type MutationStylingDeleteArgs = {
  input: StylingDeleteInput;
};


export type MutationStylingModelCreateArgs = {
  input: StylingModelInput;
};


export type MutationStylingModelUpdateArgs = {
  input: StylingModelInput;
};


export type MutationStylingUpdateArgs = {
  input: StylingUpdateInput;
};


export type MutationTopBannerContentSectionCreateArgs = {
  input: TopBannerContentSectionInput;
};


export type MutationTopBannerContentSectionUpdateArgs = {
  input: TopBannerContentSectionInput;
};


export type MutationVendorBannerCreateArgs = {
  input: VendorBannerInput;
};


export type MutationVendorBannerDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationVendorBannerUpdateArgs = {
  input: VendorBannerInput;
};


export type MutationVendorUpdateArgs = {
  input: VendorInput;
};

export type OosRateReport = {
  __typename?: 'OosRateReport';
  date?: Maybe<Scalars['ISO8601Date']>;
  lineItemCount?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Int']>;
  refundLineItemCount?: Maybe<Scalars['Int']>;
};

/** The connection type for OosRateReport. */
export type OosRateReportConnection = {
  __typename?: 'OosRateReportConnection';
  /** A list of edges. */
  edges: Array<OosRateReportEdge>;
  /** A list of nodes. */
  nodes: Array<OosRateReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OosRateReportEdge = {
  __typename?: 'OosRateReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OosRateReport;
};

export type OrderGroup = {
  __typename?: 'OrderGroup';
  id?: Maybe<Scalars['ID']>;
  lineItems?: Maybe<Array<LineItem>>;
  name?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ID']>;
};

/** The connection type for OrderGroup. */
export type OrderGroupConnection = {
  __typename?: 'OrderGroupConnection';
  /** A list of edges. */
  edges: Array<OrderGroupEdge>;
  /** A list of nodes. */
  nodes: Array<OrderGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderGroupEdge = {
  __typename?: 'OrderGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OrderGroup;
};

export enum OrderShippingStatus {
  Arrived = 'ARRIVED',
  Fulfilled = 'FULFILLED',
  InProcess = 'IN_PROCESS',
  ReadyToFulfill = 'READY_TO_FULFILL'
}

export type PackingList = {
  __typename?: 'PackingList';
  brand: Brand;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  inventoryManagementType: InventoryManagementType;
  logisticsCompany: LogisticsCompany;
  orderSheets: Array<PackingListOrderSheet>;
  packages: Array<PackingListPackage>;
  status?: Maybe<PackingListStatus>;
  unit: Scalars['Int'];
};

/** Autogenerated input type of PackingListBulkInventoryAdjustQuantity */
export type PackingListBulkInventoryAdjustQuantityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  packingListId: Scalars['ID'];
};

/** Autogenerated return type of PackingListBulkInventoryAdjustQuantity */
export type PackingListBulkInventoryAdjustQuantityPayload = {
  __typename?: 'PackingListBulkInventoryAdjustQuantityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  packingList: PackingList;
};

/** The connection type for PackingList. */
export type PackingListConnection = {
  __typename?: 'PackingListConnection';
  /** A list of edges. */
  edges: Array<PackingListEdge>;
  /** A list of nodes. */
  nodes: Array<PackingList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of PackingListCreate */
export type PackingListCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  inventoryManagementTypeId: Scalars['String'];
  logisticsCompanyId: Scalars['String'];
  packingListOrderSheets?: InputMaybe<Array<PackingListOrderSheetInput>>;
  packingListPackages: Array<PackingListPackageInput>;
  packingListTags?: InputMaybe<Array<PackingListTagInput>>;
};

/** Autogenerated return type of PackingListCreate */
export type PackingListCreatePayload = {
  __typename?: 'PackingListCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  packingList: PackingList;
};

/** An edge in a connection. */
export type PackingListEdge = {
  __typename?: 'PackingListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PackingList;
};

/** Autogenerated input type of PackingListInspecting */
export type PackingListInspectingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  packingListId: Scalars['ID'];
};

/** Autogenerated return type of PackingListInspecting */
export type PackingListInspectingPayload = {
  __typename?: 'PackingListInspectingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  packingList: PackingList;
};

/** Autogenerated input type of PackingListInventoryRegistered */
export type PackingListInventoryRegisteredInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  packingListId: Scalars['ID'];
};

/** Autogenerated return type of PackingListInventoryRegistered */
export type PackingListInventoryRegisteredPayload = {
  __typename?: 'PackingListInventoryRegisteredPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  packingList: PackingList;
};

export type PackingListOrderSheet = {
  __typename?: 'PackingListOrderSheet';
  id: Scalars['ID'];
  publicUrl: Scalars['String'];
};

export type PackingListOrderSheetInput = {
  file: Scalars['String'];
  filename: Scalars['String'];
  mimeType: Scalars['String'];
};

export type PackingListPackage = {
  __typename?: 'PackingListPackage';
  id: Scalars['ID'];
  items: Array<PackingListPackageItem>;
  packingListId: Scalars['Int'];
  trackingCompany?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
};

export type PackingListPackageInput = {
  id?: InputMaybe<Scalars['ID']>;
  packingListPackageItems: Array<PackingListPackageItemInput>;
  trackingCompany?: InputMaybe<Scalars['String']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type PackingListPackageItem = {
  __typename?: 'PackingListPackageItem';
  actualQuantity?: Maybe<Scalars['Int']>;
  featuredImage: ProductFeaturedImage;
  id: Scalars['ID'];
  inventoryItemId: Scalars['Int'];
  originalPrice?: Maybe<Scalars['Float']>;
  packingListPackageId: Scalars['Int'];
  productTitle: Scalars['String'];
  quantity: Scalars['Int'];
  rackInformation?: Maybe<Scalars['String']>;
  variant: ProductVariant;
  wholesalePrice?: Maybe<Scalars['Float']>;
};

export type PackingListPackageItemInput = {
  actualQuantity?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  inventoryItemId?: InputMaybe<Scalars['String']>;
  originalPrice?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  rackInformation?: InputMaybe<Scalars['String']>;
  wholesalePrice?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of PackingListPackageItemInventoryRegistering */
export type PackingListPackageItemInventoryRegisteringInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  packingListId: Scalars['ID'];
  packingListPackages: Array<PackingListPackageInput>;
};

/** Autogenerated return type of PackingListPackageItemInventoryRegistering */
export type PackingListPackageItemInventoryRegisteringPayload = {
  __typename?: 'PackingListPackageItemInventoryRegisteringPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  packingList: PackingList;
};

/** Autogenerated input type of PackingListPackageItemPriceSet */
export type PackingListPackageItemPriceSetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  packingListId: Scalars['ID'];
  packingListPackages: Array<PackingListPackageInput>;
};

/** Autogenerated return type of PackingListPackageItemPriceSet */
export type PackingListPackageItemPriceSetPayload = {
  __typename?: 'PackingListPackageItemPriceSetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  packingList: PackingList;
};

/** Autogenerated input type of PackingListShipped */
export type PackingListShippedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  packingListId: Scalars['ID'];
  packingListPackages: Array<PackingListPackageInput>;
};

/** Autogenerated return type of PackingListShipped */
export type PackingListShippedPayload = {
  __typename?: 'PackingListShippedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  packingList: PackingList;
};

export type PackingListStatus = {
  __typename?: 'PackingListStatus';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PackingListTagInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type Page = {
  __typename?: 'Page';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export enum Pattern {
  Fixed = 'FIXED',
  Manual = 'MANUAL',
  ProductPrice = 'PRODUCT_PRICE'
}

export type PickupBrandContentSectionInput = {
  adminTitle: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  items: Array<PickupBrandContentSectionItemInput>;
  option?: InputMaybe<Scalars['String']>;
  pageIds: Array<Scalars['ID']>;
  region: RegionInput;
  status: ContentSectionStatus;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type PickupBrandContentSectionItem = {
  __typename?: 'PickupBrandContentSectionItem';
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  platform: Platform;
  sortOrder: Scalars['Int'];
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
  vendor: Vendor;
};

export type PickupBrandContentSectionItemInput = {
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  platform: PlatformInput;
  sortOrder: Scalars['Int'];
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  vendorId: Scalars['ID'];
};

export type Platform = {
  __typename?: 'Platform';
  app: Scalars['Boolean'];
  web: Scalars['Boolean'];
};

export type PlatformInput = {
  app: Scalars['Boolean'];
  web: Scalars['Boolean'];
};

/** Autogenerated input type of PointAddByAdmin */
export type PointAddByAdminInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  expiresIn: Scalars['Int'];
  point: Scalars['Int'];
  title: Scalars['String'];
};

/** Autogenerated return type of PointAddByAdmin */
export type PointAddByAdminPayload = {
  __typename?: 'PointAddByAdminPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  pointHistory: PointHistory;
};

export type PointHistory = {
  __typename?: 'PointHistory';
  amount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  displayExpiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  kind: PointHistoryKind;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for PointHistory. */
export type PointHistoryConnection = {
  __typename?: 'PointHistoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PointHistoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PointHistory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PointHistoryEdge = {
  __typename?: 'PointHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PointHistory>;
};

export enum PointHistoryKind {
  Addition = 'ADDITION',
  Consumption = 'CONSUMPTION',
  Expiration = 'EXPIRATION'
}

/** Autogenerated input type of PointSubtractByAdmin */
export type PointSubtractByAdminInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['ID'];
  point: Scalars['Int'];
  title: Scalars['String'];
};

/** Autogenerated return type of PointSubtractByAdmin */
export type PointSubtractByAdminPayload = {
  __typename?: 'PointSubtractByAdminPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  pointHistory: PointHistory;
};

export type Product = RegionInterface & {
  __typename?: 'Product';
  archived?: Maybe<Scalars['Boolean']>;
  comingSoon?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  description: Scalars['String'];
  descriptionEn: Scalars['String'];
  featuredImage?: Maybe<ShopifyProductImage>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<ShopifyProductImage>>;
  inventoryCount?: Maybe<Scalars['Int']>;
  inventoryManagementType?: Maybe<InventoryManagement>;
  inventoryNotificationType?: Maybe<Scalars['Boolean']>;
  isSale?: Maybe<Scalars['Boolean']>;
  itemCategory?: Maybe<ItemCategory>;
  itemGenres: Array<ItemGenre>;
  knitting?: Maybe<Knitting>;
  materials?: Maybe<Array<Material>>;
  preorderDate?: Maybe<Scalars['ISO8601DateTime']>;
  primaryPrice?: Maybe<Scalars['Int']>;
  productFeatures?: Maybe<Array<ProductFeature>>;
  productType?: Maybe<ProductType>;
  region: Region;
  restockRequestedCount?: Maybe<Scalars['Int']>;
  searchTags: Array<SearchTag>;
  seasonality: ProductSeasonality;
  secondaryCategoryId?: Maybe<Scalars['ID']>;
  serviceType?: Maybe<ProductServiceType>;
  shop?: Maybe<Brand>;
  sizeChart?: Maybe<Scalars['String']>;
  sizeChartFormatId?: Maybe<Scalars['Int']>;
  sizeChartV2?: Maybe<SizeChart>;
  status?: Maybe<ProductStatus>;
  tags: Array<Scalars['String']>;
  tertiaryCategoryId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  titleEn?: Maybe<Scalars['String']>;
  unclosedPackingListCount?: Maybe<Scalars['Int']>;
  unisex: Scalars['Boolean'];
  variants?: Maybe<Array<ProductVariant>>;
  vendor?: Maybe<Vendor>;
};

/** Autogenerated input type of ProductAppendImages */
export type ProductAppendImagesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
};

/** Autogenerated return type of ProductAppendImages */
export type ProductAppendImagesPayload = {
  __typename?: 'ProductAppendImagesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newImages: Array<ShopifyProductImage>;
};

/** Autogenerated input type of ProductArchived */
export type ProductArchivedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ProductArchived */
export type ProductArchivedPayload = {
  __typename?: 'ProductArchivedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

/** Autogenerated return type of ProductBulkChangeStatus */
export type ProductBulkChangeStatusPayload = {
  __typename?: 'ProductBulkChangeStatusPayload';
  products: Array<Product>;
};

export enum ProductChangeRequestStatus {
  /** 進行中の（{Archived}済みでない）{ChangeRequest}が存在する */
  HasInProgressChangeRequest = 'HAS_IN_PROGRESS_CHANGE_REQUEST',
  /** 進行中の{ChangeRequest}が存在しない */
  HasNoInProgressChangeRequest = 'HAS_NO_IN_PROGRESS_CHANGE_REQUEST'
}

export type ProductCollection = RegionInterface & {
  __typename?: 'ProductCollection';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  disjunctive: Scalars['Boolean'];
  handle: Scalars['String'];
  id: Scalars['ID'];
  region: Region;
  rules: Array<ProductCollectionRule>;
  sortType: ProductCollectionSort;
  status: ProductCollectionStatus;
  title: Scalars['String'];
  titleEn: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ProductCollectionColumnRule {
  Color = 'COLOR',
  Gender = 'GENDER',
  Handle = 'HANDLE',
  Id = 'ID',
  Price = 'PRICE',
  PriceType = 'PRICE_TYPE',
  ProductType = 'PRODUCT_TYPE',
  Quantity = 'QUANTITY',
  SecondaryCategoryId = 'SECONDARY_CATEGORY_ID',
  ShopId = 'SHOP_ID',
  Slug = 'SLUG',
  Tag = 'TAG',
  TertiaryCategoryId = 'TERTIARY_CATEGORY_ID',
  Title = 'TITLE',
  VendorId = 'VENDOR_ID'
}

/** The connection type for ProductCollection. */
export type ProductCollectionConnection = {
  __typename?: 'ProductCollectionConnection';
  /** A list of edges. */
  edges: Array<ProductCollectionEdge>;
  /** A list of nodes. */
  nodes: Array<ProductCollection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductCollectionEdge = {
  __typename?: 'ProductCollectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductCollection;
};

export type ProductCollectionInput = {
  description?: InputMaybe<Scalars['String']>;
  disjunctive: Scalars['Boolean'];
  handle: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  region: RegionInput;
  rules: Array<ProductCollectionRuleInput>;
  sortType: ProductCollectionSort;
  status: ProductCollectionStatus;
  title: Scalars['String'];
  titleEn: Scalars['String'];
};

export enum ProductCollectionRelationRule {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  Unequals = 'UNEQUALS'
}

export type ProductCollectionRule = {
  __typename?: 'ProductCollectionRule';
  column: ProductCollectionColumnRule;
  condition: Scalars['String'];
  relation: ProductCollectionRelationRule;
};

export type ProductCollectionRuleConditions = {
  __typename?: 'ProductCollectionRuleConditions';
  allowedRelations: Array<ProductCollectionRelationRule>;
  columnRule: ProductCollectionColumnRule;
  defaultRelation: ProductCollectionRelationRule;
};

export type ProductCollectionRuleInput = {
  column: ProductCollectionColumnRule;
  condition: Scalars['String'];
  relation: ProductCollectionRelationRule;
};

export enum ProductCollectionSort {
  BestSelling = 'BEST_SELLING',
  Latest = 'LATEST',
  PriceHighToLow = 'PRICE_HIGH_TO_LOW',
  PriceLowToHigh = 'PRICE_LOW_TO_HIGH',
  Relevance = 'RELEVANCE'
}

export enum ProductCollectionStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

/** Autogenerated input type of ProductComingSoonDateTimeUpdate */
export type ProductComingSoonDateTimeUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comingSoonDatetime?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ProductComingSoonDateTimeUpdate */
export type ProductComingSoonDateTimeUpdatePayload = {
  __typename?: 'ProductComingSoonDateTimeUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

/** The connection type for Product. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProductCoupon = RegionInterface & {
  __typename?: 'ProductCoupon';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  discountType: Scalars['String'];
  disjunctive: Scalars['Boolean'];
  endsAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  region: Region;
  rules: Array<ProductCouponRule>;
  startsAt: Scalars['ISO8601DateTime'];
  status: ProductCouponStatus;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  usageCondition: Scalars['String'];
};

/** The connection type for ProductCoupon. */
export type ProductCouponConnection = {
  __typename?: 'ProductCouponConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductCouponEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductCoupon>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of ProductCouponCreate */
export type ProductCouponCreatePayload = {
  __typename?: 'ProductCouponCreatePayload';
  productCoupon: ProductCoupon;
};

/** An edge in a connection. */
export type ProductCouponEdge = {
  __typename?: 'ProductCouponEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductCoupon>;
};

export type ProductCouponInput = {
  code?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  discountType: Scalars['String'];
  disjunctive: Scalars['Boolean'];
  endsAt: Scalars['ISO8601DateTime'];
  id?: InputMaybe<Scalars['ID']>;
  region: RegionInput;
  rules: Array<ProductCouponRuleInput>;
  startsAt: Scalars['ISO8601DateTime'];
  status: ProductCouponStatus;
  title: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  usageCondition: Scalars['String'];
};

export type ProductCouponRule = {
  __typename?: 'ProductCouponRule';
  columnRule: ProductCouponRuleColumnRule;
  condition: Scalars['String'];
  relationRule: ProductCouponRuleRelationRule;
};

export enum ProductCouponRuleColumnRule {
  ShopId = 'SHOP_ID',
  Tag = 'TAG',
  VendorId = 'VENDOR_ID'
}

export type ProductCouponRuleInput = {
  columnRule: ProductCouponRuleColumnRule;
  condition: Scalars['String'];
  relationRule: ProductCouponRuleRelationRule;
};

export enum ProductCouponRuleRelationRule {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  Unequals = 'UNEQUALS'
}

export enum ProductCouponStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

/** Autogenerated return type of ProductCouponUpdate */
export type ProductCouponUpdatePayload = {
  __typename?: 'ProductCouponUpdatePayload';
  productCoupon: ProductCoupon;
};

/** Autogenerated input type of ProductCreate */
export type ProductCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comingSoon?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionEn?: InputMaybe<Scalars['String']>;
  gender: Scalars['String'];
  images: Array<Scalars['String']>;
  inventoryManagementType: InventoryManagement;
  itemCategory: Scalars['String'];
  itemGenres?: InputMaybe<Array<ItemGenreInput>>;
  knitting: Scalars['String'];
  materials: Array<MaterialInput>;
  originalPrice: Scalars['Int'];
  preorderDate?: InputMaybe<Scalars['String']>;
  productFeatures?: InputMaybe<Array<ProductFeatureInput>>;
  productType?: InputMaybe<ProductType>;
  region: RegionInput;
  seasonality?: InputMaybe<ProductSeasonalityInput>;
  secondaryCategoryId?: InputMaybe<Scalars['ID']>;
  shopId: Scalars['String'];
  sizeChart: Scalars['String'];
  sizeChartFormat: Scalars['String'];
  sizeChartV2?: InputMaybe<SizeChartInput>;
  status: ProductStatus;
  tags?: InputMaybe<Array<Scalars['String']>>;
  takeRate: Scalars['Float'];
  taxRate: Scalars['Float'];
  tertiaryCategoryId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  titleEn: Scalars['String'];
  unisex: Scalars['Boolean'];
  variants: Array<VariantInput>;
  vendorId: Scalars['String'];
};

/** Autogenerated return type of ProductCreate */
export type ProductCreatePayload = {
  __typename?: 'ProductCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

/** Autogenerated input type of ProductDeleteImages */
export type ProductDeleteImagesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  imageIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of ProductDeleteImages */
export type ProductDeleteImagesPayload = {
  __typename?: 'ProductDeleteImagesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedImageIds: Array<Scalars['ID']>;
};

/** Autogenerated input type of ProductDraft */
export type ProductDraftInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ProductDraft */
export type ProductDraftPayload = {
  __typename?: 'ProductDraftPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Product>;
};

export type ProductFeature = {
  __typename?: 'ProductFeature';
  feature: Feature;
  otherText?: Maybe<Scalars['String']>;
};

export type ProductFeatureInput = {
  featureId: Scalars['ID'];
  otherText?: InputMaybe<Scalars['String']>;
};

export type ProductFeaturedImage = {
  __typename?: 'ProductFeaturedImage';
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ProductImageUpload */
export type ProductImageUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  images: Array<ImageInput>;
};

/** Autogenerated return type of ProductImageUpload */
export type ProductImageUploadPayload = {
  __typename?: 'ProductImageUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  imageUrls: Array<Scalars['String']>;
};

export type ProductImportJob = {
  __typename?: 'ProductImportJob';
  createdAt: Scalars['ISO8601DateTime'];
  fileUrl: Scalars['String'];
  histories: Array<ProductImportJobStatusHistory>;
  id: Scalars['ID'];
  inventoryManagementType: InventoryManagementType;
  shop: Brand;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for ProductImportJob. */
export type ProductImportJobConnection = {
  __typename?: 'ProductImportJobConnection';
  /** A list of edges. */
  edges: Array<ProductImportJobEdge>;
  /** A list of nodes. */
  nodes: Array<ProductImportJob>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductImportJobEdge = {
  __typename?: 'ProductImportJobEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductImportJob;
};

export enum ProductImportJobStatus {
  Failed = 'FAILED',
  Queued = 'QUEUED',
  Success = 'SUCCESS',
  Working = 'WORKING'
}

export type ProductImportJobStatusHistory = {
  __typename?: 'ProductImportJobStatusHistory';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  status: ProductImportJobStatus;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of ProductPreOrderDateUpdate */
export type ProductPreOrderDateUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  preOrderDate?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ProductPreOrderDateUpdate */
export type ProductPreOrderDateUpdatePayload = {
  __typename?: 'ProductPreOrderDateUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

/** Autogenerated input type of ProductReorderImages */
export type ProductReorderImagesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  moves: Array<MoveInput>;
};

/** Autogenerated return type of ProductReorderImages */
export type ProductReorderImagesPayload = {
  __typename?: 'ProductReorderImagesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shopifyJobId: Scalars['ID'];
};

export type ProductSearch = {
  __typename?: 'ProductSearch';
  featuredImage: ProductFeaturedImage;
  id: Scalars['ID'];
  shop?: Maybe<Brand>;
  title: Scalars['String'];
  titleEn?: Maybe<Scalars['String']>;
  variants: Array<ProductVariant>;
  vendor: Vendor;
};

export type ProductSeasonality = {
  __typename?: 'ProductSeasonality';
  autumnItem: Scalars['Boolean'];
  springItem: Scalars['Boolean'];
  summerItem: Scalars['Boolean'];
  winterItem: Scalars['Boolean'];
};

export type ProductSeasonalityInput = {
  autumnItem: Scalars['Boolean'];
  springItem: Scalars['Boolean'];
  summerItem: Scalars['Boolean'];
  winterItem: Scalars['Boolean'];
};

export enum ProductServiceType {
  Cafe24 = 'CAFE24',
  SixtyPercent = 'SIXTY_PERCENT'
}

export enum ProductSizeChartVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export enum ProductStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Published = 'PUBLISHED'
}

export type ProductStatusInput = {
  id: Scalars['ID'];
  status: ProductStatus;
};

export enum ProductType {
  Collaboration = 'COLLABORATION',
  LuckyBox = 'LUCKY_BOX',
  Pb = 'PB',
  Popup = 'POPUP',
  Select = 'SELECT',
  SpringLuckyBox = 'SPRING_LUCKY_BOX',
  Stocked = 'STOCKED',
  SummerLuckyBox = 'SUMMER_LUCKY_BOX',
  Test = 'TEST',
  ThankyouBox_2022 = 'THANKYOU_BOX_2022',
  WinterLuckyBox = 'WINTER_LUCKY_BOX'
}

/** Autogenerated input type of ProductUnarchived */
export type ProductUnarchivedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ProductUnarchived */
export type ProductUnarchivedPayload = {
  __typename?: 'ProductUnarchivedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

/** Autogenerated input type of ProductUpdate */
export type ProductUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  comingSoon?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionEn?: InputMaybe<Scalars['String']>;
  gender: Scalars['String'];
  id: Scalars['ID'];
  images?: InputMaybe<Array<Scalars['String']>>;
  inventoryManagementType: InventoryManagement;
  itemCategory: Scalars['String'];
  itemGenres?: InputMaybe<Array<ItemGenreInput>>;
  knitting: Scalars['String'];
  materials: Array<MaterialInput>;
  originalPrice: Scalars['Int'];
  preorderDate?: InputMaybe<Scalars['String']>;
  productFeatures?: InputMaybe<Array<ProductFeatureInput>>;
  productType?: InputMaybe<ProductType>;
  region: RegionInput;
  seasonality?: InputMaybe<ProductSeasonalityInput>;
  secondaryCategoryId?: InputMaybe<Scalars['ID']>;
  shopId: Scalars['String'];
  sizeChart: Scalars['String'];
  sizeChartFormat: Scalars['String'];
  sizeChartV2?: InputMaybe<SizeChartInput>;
  status: ProductStatus;
  tags?: InputMaybe<Array<Scalars['String']>>;
  takeRate: Scalars['Float'];
  taxRate: Scalars['Float'];
  tertiaryCategoryId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  titleEn: Scalars['String'];
  unisex: Scalars['Boolean'];
  variants: Array<VariantInput>;
  vendorId: Scalars['String'];
};

/** Autogenerated return type of ProductUpdate */
export type ProductUpdatePayload = {
  __typename?: 'ProductUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  product: Product;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  color: Color;
  compareAtPrice?: Maybe<Scalars['Int']>;
  createdAt: Scalars['ISO8601DateTime'];
  id?: Maybe<Scalars['ID']>;
  inventoryItemId?: Maybe<Scalars['ID']>;
  inventoryQuantity: Scalars['Int'];
  isSale: Scalars['Boolean'];
  legacySizeName?: Maybe<Scalars['String']>;
  originalPrice: Scalars['Int'];
  price: Scalars['Int'];
  product: Product;
  size: Size;
  sku: Scalars['String'];
  takeRate: Scalars['Float'];
  taxRate: Scalars['Float'];
  title: Scalars['String'];
};

/** The connection type for ProductVariant. */
export type ProductVariantConnection = {
  __typename?: 'ProductVariantConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductVariantEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductVariant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductVariantEdge = {
  __typename?: 'ProductVariantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductVariant>;
};

export enum ProductVariantSortKeys {
  Id = 'ID',
  InventoryQuantity = 'INVENTORY_QUANTITY'
}

/** Autogenerated input type of ProductVariantUpdateQuantity */
export type ProductVariantUpdateQuantityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  inventoryQuantity: Scalars['Int'];
};

/** Autogenerated return type of ProductVariantUpdateQuantity */
export type ProductVariantUpdateQuantityPayload = {
  __typename?: 'ProductVariantUpdateQuantityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  productVariant: ProductVariant;
};

export enum ProductsSortType {
  /** 作成日（新着順） */
  NewToOld = 'NewToOld',
  /** 作成日（古い順） */
  OldToNew = 'OldToNew',
  /** 商品名（A-Z） */
  TitleAtoZ = 'TitleAtoZ',
  /** 商品名（Z-A） */
  TitleZtoA = 'TitleZtoA'
}

export type ProductsStatusCount = {
  __typename?: 'ProductsStatusCount';
  draft: Scalars['Int'];
  pending: Scalars['Int'];
  published: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  adjustmentPatterns: Array<AdjustmentPattern>;
  adjustments: AdjustmentConnection;
  adminFiles: AdminFileConnection;
  announcements: AnnouncementConnection;
  appTabTypes: Array<AppTabType>;
  area: Area;
  areas: AreaConnection;
  article: Article;
  articleAuthors: ArticleAuthorConnection;
  articleCategories: Array<ArticleCategory>;
  articleTags: Array<Scalars['String']>;
  articles: ArticleConnection;
  bestSellerItems: BestSellerItemConnection;
  blogs: BlogConnection;
  brandInventoryManagementTypes: Array<InventoryManagementType>;
  brandLogisticsCompanies: Array<LogisticsCompany>;
  brandPackingList: PackingList;
  brandPackingLists: PackingListConnection;
  brandProducts: ProductConnection;
  brandShopSale: ShopSale;
  brandShopSales: ShopSaleConnection;
  brandShopSalesStatusCount: Scalars['Int'];
  categories: Array<Category>;
  colors: Array<Color>;
  contentCollection: ContentCollection;
  contentCollectionContentSortOrders: Array<ContentCollectionContentSortOrder>;
  contentCollectionRuleConditions: Array<ContentCollectionRuleConditions>;
  contentCollections: Array<ContentCollection>;
  contentFeature: ContentFeature;
  contentFeatureTags: Array<Scalars['String']>;
  contentFeatures: ContentFeatureConnection;
  contentPage: ContentPage;
  contentPageTags: ContentPageTagConnection;
  contentPages: ContentPageConnection;
  contentSection: ContentSection;
  contentSectionTypes: Array<ContentSectionType>;
  contentSections: ContentSectionConnection;
  currentShop: Brand;
  currentStaff: Staff;
  customer: Customer;
  customers: CustomerConnection;
  domesticAccounts: DomesticAccountConnection;
  featureGroups: Array<FeatureGroup>;
  features: Array<Feature>;
  foreignCurrencyReceivableAccounts: ForeignCurrencyReceivableAccountConnection;
  groups: Array<Group>;
  inventoryManagementTypes: Array<InventoryManagementType>;
  issue: Issue;
  issueAssigners: Array<IssueActor>;
  issueFiles: Array<IssueFile>;
  issueFollowers: Array<IssueActor>;
  issueStatusCount: Scalars['Int'];
  issueTags: Array<Scalars['String']>;
  issues: IssueConnection;
  itemCategories: Array<ItemCategory>;
  itemGenres: Array<ItemGenre>;
  knittings: Array<Knitting>;
  languages: Array<Language>;
  lineItem: LineItem;
  lineItems: LineItemConnection;
  lineItemsSalesReports: LineItemsSalesReportConnection;
  logisticsCompanies: Array<LogisticsCompany>;
  logisticsCompaniesOrderGroups: OrderGroupConnection;
  logisticsCompaniesShops: BrandConnection;
  logisticsCompany: LogisticsCompany;
  logisticsCompanyIssue: Issue;
  logisticsCompanyIssueFiles: Array<IssueFile>;
  logisticsCompanyIssueTags: Array<Scalars['String']>;
  logisticsCompanyIssues: IssueConnection;
  logisticsCompanyOrderShippingStatusCount: Scalars['Int'];
  logisticsCompanyPackingList: PackingList;
  logisticsCompanyPackingLists: PackingListConnection;
  materials: MaterialAttribute;
  oosRateReports: OosRateReportConnection;
  packingList: PackingList;
  packingLists: PackingListConnection;
  pages: Array<Page>;
  product: Product;
  productCollection: ProductCollection;
  productCollectionRuleConditions: Array<ProductCollectionRuleConditions>;
  productCollections: ProductCollectionConnection;
  productCoupon: ProductCoupon;
  productCoupons: ProductCouponConnection;
  productImages: Array<ShopifyProductImage>;
  productImportJob: ProductImportJob;
  productImportJobs: ProductImportJobConnection;
  productSearch: Array<ProductSearch>;
  productTags: Array<Scalars['String']>;
  productVariants: ProductVariantConnection;
  products: ProductConnection;
  productsStatusCount: ProductsStatusCount;
  rewardPaymentMethods: Array<RewardPaymentMethod>;
  salesReport: SalesReport;
  salesReportLineItems: SalesReportLineItemConnection;
  salesReports: SalesReportConnection;
  salesReportsMetadata: SalesReportsMetadata;
  searchDictionaries: SearchDictionaryConnection;
  searchDictionary: SearchDictionary;
  searchTags: SearchTagConnection;
  shippingConfigs: Array<ShippingConfig>;
  shop: Brand;
  shopIssue: Issue;
  shopIssueFiles: Array<IssueFile>;
  shopIssueTags: Array<Scalars['String']>;
  shopIssues: IssueConnection;
  shopLatestShopTermsOfUse?: Maybe<ShopTermsOfUse>;
  shopLineItems: LineItemConnection;
  shopLineItemsTabsCount: LineItemsTabsCount;
  shopProductImportJob: ProductImportJob;
  shopProductImportJobs: ProductImportJobConnection;
  shopProductVariants: ProductVariantConnection;
  shopSale: ShopSale;
  shopSales: ShopSaleConnection;
  shopSalesStatusCount: Scalars['Int'];
  shopTermsOfUse: ShopTermsOfUse;
  shopTermsOfUses: ShopTermsOfUseConnection;
  shopVendorSizeMaster: ShopVendorSizeMaster;
  shopVendorSizeMasters: ShopVendorSizeMasterConnection;
  shopVendorSizes: Array<ShopVendorSize>;
  shops: BrandConnection;
  sizeChartFormats: Array<SizeChartFormat>;
  sizes: Array<Size>;
  styling: Styling;
  stylingModel: StylingModel;
  stylingModels: StylingModelConnection;
  stylings: StylingConnection;
  stylingsStatusCount: StylingsStatusCount;
  thirdPartyServiceCredentials: Array<ThirdPartyServiceCredential>;
  topRestockRequestItems: TopRestockRequestItemConnection;
  transferWiseRequirements: WiseRequirements;
  vendor: Vendor;
  vendors: VendorConnection;
};


export type QueryAdjustmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['ID']>;
};


export type QueryAdminFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<AnnouncementStatus>;
};


export type QueryAreaArgs = {
  id: Scalars['ID'];
};


export type QueryAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
};


export type QueryArticleAuthorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  type: ArticleAuthorType;
};


export type QueryArticleCategoriesArgs = {
  blogId: Scalars['ID'];
  mainCategoryId?: InputMaybe<Scalars['ID']>;
};


export type QueryArticleTagsArgs = {
  query: Scalars['String'];
};


export type QueryArticlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  authorId?: InputMaybe<Scalars['ID']>;
  authorType?: InputMaybe<ArticleAuthorType>;
  before?: InputMaybe<Scalars['String']>;
  blogId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  mainCategoryId?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<RegionType>;
  sortType?: InputMaybe<ArticleSortType>;
  status?: InputMaybe<ArticleStatus>;
  subCategoryId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryBestSellerItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate: Scalars['ISO8601Date'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};


export type QueryBlogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryBrandPackingListArgs = {
  id: Scalars['ID'];
};


export type QueryBrandPackingListsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryBrandProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inventoryManagementType?: InputMaybe<InventoryManagement>;
  last?: InputMaybe<Scalars['Int']>;
  preorderDate?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  restockRequested?: InputMaybe<Scalars['Boolean']>;
  serviceType?: InputMaybe<ProductServiceType>;
  sizeChartVersion?: InputMaybe<ProductSizeChartVersion>;
  sortType?: InputMaybe<ProductsSortType>;
  status?: InputMaybe<ProductStatus>;
};


export type QueryBrandShopSaleArgs = {
  id: Scalars['ID'];
};


export type QueryBrandShopSalesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  startsOnFrom?: InputMaybe<Scalars['ISO8601DateTime']>;
  startsOnTo?: InputMaybe<Scalars['ISO8601DateTime']>;
  statuses?: InputMaybe<Array<ShopSaleStatus>>;
};


export type QueryBrandShopSalesStatusCountArgs = {
  statuses: Array<ShopSaleStatus>;
};


export type QueryCategoriesArgs = {
  parentId?: InputMaybe<Scalars['ID']>;
};


export type QueryContentCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryContentCollectionContentSortOrdersArgs = {
  contentCollectionContentType: ContentCollectionContentType;
};


export type QueryContentCollectionRuleConditionsArgs = {
  contentCollectionContentType: ContentCollectionContentType;
};


export type QueryContentCollectionsArgs = {
  appTabTypeId: Scalars['ID'];
  contentTypes?: InputMaybe<Array<ContentCollectionContentType>>;
  query?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<RegionType>;
  statuses?: InputMaybe<Array<ContentCollectionStatus>>;
};


export type QueryContentFeatureArgs = {
  id: Scalars['ID'];
};


export type QueryContentFeatureTagsArgs = {
  name: Scalars['String'];
};


export type QueryContentFeaturesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<ContentFeatureCategory>>;
  contentTypes?: InputMaybe<Array<ContentFeatureContentType>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<ContentFeatureStatus>>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryContentPageArgs = {
  id: Scalars['ID'];
};


export type QueryContentPageTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};


export type QueryContentPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<RegionType>;
  status?: InputMaybe<ContentPageStatus>;
};


export type QueryContentSectionArgs = {
  id: Scalars['ID'];
};


export type QueryContentSectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  contentSectionTypeId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageId?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<RegionType>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  states?: InputMaybe<Array<CustomerState>>;
};


export type QueryDomesticAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryForeignCurrencyReceivableAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryIssueArgs = {
  id: Scalars['ID'];
};


export type QueryIssueAssignersArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryIssueFilesArgs = {
  id: Scalars['ID'];
};


export type QueryIssueFollowersArgs = {
  query: Scalars['String'];
};


export type QueryIssueStatusCountArgs = {
  statuses: Array<IssueStatus>;
};


export type QueryIssueTagsArgs = {
  query: Scalars['String'];
};


export type QueryIssuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<IssueCategory>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  priorities?: InputMaybe<Array<IssuePriority>>;
  query?: InputMaybe<Scalars['String']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<IssueStatus>>;
  vendors?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryLineItemArgs = {
  id: Scalars['ID'];
};


export type QueryLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLineItemsSalesReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate: Scalars['ISO8601Date'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};


export type QueryLogisticsCompaniesOrderGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deliveryDelayMailSentAtNull?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  sendDelayMailSentAtNull?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['ID']>;
};


export type QueryLogisticsCompaniesShopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryLogisticsCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryLogisticsCompanyIssueArgs = {
  id: Scalars['ID'];
};


export type QueryLogisticsCompanyIssueFilesArgs = {
  id: Scalars['ID'];
};


export type QueryLogisticsCompanyIssueTagsArgs = {
  query: Scalars['String'];
};


export type QueryLogisticsCompanyIssuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<IssueCategory>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  priorities?: InputMaybe<Array<IssuePriority>>;
  query?: InputMaybe<Scalars['String']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<IssueStatus>>;
};


export type QueryLogisticsCompanyOrderShippingStatusCountArgs = {
  status: OrderShippingStatus;
};


export type QueryLogisticsCompanyPackingListArgs = {
  id: Scalars['ID'];
};


export type QueryLogisticsCompanyPackingListsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryOosRateReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate: Scalars['ISO8601Date'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};


export type QueryPackingListArgs = {
  id: Scalars['ID'];
};


export type QueryPackingListsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryProductCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  region: RegionType;
  status?: InputMaybe<ProductCollectionStatus>;
};


export type QueryProductCouponArgs = {
  id: Scalars['ID'];
};


export type QueryProductCouponsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<RegionType>;
  status?: InputMaybe<ProductCouponStatus>;
};


export type QueryProductImagesArgs = {
  productId: Scalars['ID'];
};


export type QueryProductImportJobArgs = {
  id: Scalars['ID'];
};


export type QueryProductImportJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryProductSearchArgs = {
  inventoryManagementType?: InputMaybe<InventoryManagement>;
  limit?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProductStatus>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryProductTagsArgs = {
  name: Scalars['String'];
};


export type QueryProductVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  productStatus?: InputMaybe<ProductStatus>;
  query?: InputMaybe<Scalars['String']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<ProductVariantSortKeys>;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  changeRequestStatus?: InputMaybe<ProductChangeRequestStatus>;
  first?: InputMaybe<Scalars['Int']>;
  inventoryManagementType?: InputMaybe<InventoryManagement>;
  inventoryNotificationType?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  preorderDate?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<RegionType>;
  serviceType?: InputMaybe<ProductServiceType>;
  shopId?: InputMaybe<Scalars['ID']>;
  sortType?: InputMaybe<ProductsSortType>;
  status?: InputMaybe<ProductStatus>;
  vendor?: InputMaybe<Scalars['String']>;
};


export type QuerySalesReportArgs = {
  month?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['ID']>;
};


export type QuerySalesReportLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['ID']>;
};


export type QuerySalesReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['String']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<SalesReportSortKeys>;
  status?: InputMaybe<SalesReportStatus>;
};


export type QuerySalesReportsMetadataArgs = {
  month: Scalars['String'];
  status?: InputMaybe<SalesReportStatus>;
};


export type QuerySearchDictionariesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};


export type QuerySearchDictionaryArgs = {
  id: Scalars['ID'];
};


export type QuerySearchTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryShopArgs = {
  id: Scalars['ID'];
};


export type QueryShopIssueArgs = {
  id: Scalars['ID'];
};


export type QueryShopIssueFilesArgs = {
  id: Scalars['ID'];
};


export type QueryShopIssueTagsArgs = {
  query: Scalars['String'];
};


export type QueryShopIssuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<IssueCategory>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  priorities?: InputMaybe<Array<IssuePriority>>;
  query?: InputMaybe<Scalars['String']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<IssueStatus>>;
};


export type QueryShopLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cancelStatus?: InputMaybe<LineItemCancelStatusType>;
  confirmedStatus?: InputMaybe<LineItemConfirmedStatusType>;
  date?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  preorderDateNull?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  shippingStatus?: InputMaybe<LineItemShippingStatus>;
};


export type QueryShopProductImportJobArgs = {
  id: Scalars['ID'];
};


export type QueryShopProductImportJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryShopProductVariantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  productStatus?: InputMaybe<ProductStatus>;
  query?: InputMaybe<Scalars['String']>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  sortKey?: InputMaybe<ProductVariantSortKeys>;
};


export type QueryShopSaleArgs = {
  id: Scalars['ID'];
};


export type QueryShopSalesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  startsOnFrom?: InputMaybe<Scalars['ISO8601DateTime']>;
  startsOnTo?: InputMaybe<Scalars['ISO8601DateTime']>;
  statuses?: InputMaybe<Array<ShopSaleStatus>>;
};


export type QueryShopSalesStatusCountArgs = {
  statuses: Array<ShopSaleStatus>;
};


export type QueryShopTermsOfUseArgs = {
  id: Scalars['ID'];
};


export type QueryShopTermsOfUsesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryShopVendorSizeMasterArgs = {
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryShopVendorSizeMastersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryShopVendorSizesArgs = {
  itemCategoryId: Scalars['ID'];
  shopId?: InputMaybe<Scalars['ID']>;
  vendorId: Scalars['ID'];
};


export type QueryShopsArgs = {
  account?: InputMaybe<Array<Account>>;
  accountStatus?: InputMaybe<Array<AccountStatus>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Array<ProductType>>;
  country?: InputMaybe<Array<CountryCode>>;
  defaultTakeRate?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  logisticsCompanyId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<RegionType>;
  rewardPaymentMethodId?: InputMaybe<Scalars['ID']>;
  shippingConfigIds?: InputMaybe<Array<Scalars['ID']>>;
  shopType?: InputMaybe<Array<ShopType>>;
  sortType?: InputMaybe<ShopSortType>;
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QuerySizeChartFormatsArgs = {
  itemCategoryId: Scalars['ID'];
};


export type QuerySizesArgs = {
  itemCategoryId: Scalars['ID'];
};


export type QueryStylingArgs = {
  id: Scalars['ID'];
};


export type QueryStylingModelArgs = {
  id: Scalars['ID'];
};


export type QueryStylingModelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryStylingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<StylingStatus>;
  vendor?: InputMaybe<Scalars['String']>;
};


export type QueryTopRestockRequestItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate: Scalars['ISO8601Date'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};


export type QueryTransferWiseRequirementsArgs = {
  currency: Scalars['String'];
};


export type QueryVendorArgs = {
  id: Scalars['ID'];
};


export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  areaId?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['ID']>;
  sortType?: InputMaybe<VendorSortType>;
  status?: InputMaybe<VendorStatus>;
};

export type Region = {
  __typename?: 'Region';
  global: Scalars['Boolean'];
  jp: Scalars['Boolean'];
};

export type RegionInput = {
  global: Scalars['Boolean'];
  jp: Scalars['Boolean'];
};

export type RegionInterface = {
  region: Region;
};

export enum RegionType {
  Global = 'GLOBAL',
  Jp = 'JP'
}

/** Autogenerated return type of Reorder */
export type ReorderPayload = {
  __typename?: 'ReorderPayload';
  area: Area;
};

export type ReplannedShipping = {
  __typename?: 'ReplannedShipping';
  date: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  lineItemId: Scalars['ID'];
};

/** Autogenerated input type of ReplannedShippingCreate */
export type ReplannedShippingCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  date: Scalars['ISO8601DateTime'];
  lineItemId: Scalars['ID'];
};

/** Autogenerated return type of ReplannedShippingCreate */
export type ReplannedShippingCreatePayload = {
  __typename?: 'ReplannedShippingCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  replannedShipping: ReplannedShipping;
};

export type RewardPaymentMethod = {
  __typename?: 'RewardPaymentMethod';
  id: Scalars['ID'];
  type: RewardPaymentMethodType;
};

export enum RewardPaymentMethodType {
  DomesticBank = 'DOMESTIC_BANK',
  ForeignBank = 'FOREIGN_BANK',
  Paypal = 'PAYPAL',
  Wise = 'WISE'
}

export type RewardPaymentMethodTypeInput = {
  id: Scalars['ID'];
};

export type SalesReport = {
  __typename?: 'SalesReport';
  adjustedPrice?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  invoiceNote?: Maybe<InvoiceNote>;
  month?: Maybe<Scalars['String']>;
  salesDiscount?: Maybe<Scalars['Int']>;
  shop: Brand;
  status?: Maybe<SalesReportStatus>;
  takeRate?: Maybe<Scalars['Float']>;
  totalOrderCount?: Maybe<Scalars['Int']>;
  totalOrderQuantity?: Maybe<Scalars['Int']>;
  totalSales?: Maybe<Scalars['Int']>;
  totalSalesFees?: Maybe<Scalars['Int']>;
  totalSalesUnpaid?: Maybe<Scalars['Int']>;
  totalSalesWithoutTax?: Maybe<Scalars['Int']>;
};

/** The connection type for SalesReport. */
export type SalesReportConnection = {
  __typename?: 'SalesReportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesReportEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SalesReport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SalesReportEdge = {
  __typename?: 'SalesReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesReport>;
};

/** Autogenerated input type of SalesReportFix */
export type SalesReportFixInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  month: Scalars['String'];
  shopId: Scalars['ID'];
};

/** Autogenerated return type of SalesReportFix */
export type SalesReportFixPayload = {
  __typename?: 'SalesReportFixPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  salesReport: SalesReport;
};

export type SalesReportLineItem = {
  __typename?: 'SalesReportLineItem';
  compareAtPrice?: Maybe<Scalars['Float']>;
  compareAtPriceTotal?: Maybe<Scalars['Float']>;
  createdAt: Scalars['ISO8601DateTime'];
  feeTotal?: Maybe<Scalars['Float']>;
  fulfilledAt: Scalars['ISO8601DateTime'];
  fulfilledQuantity: Scalars['Int'];
  id: Scalars['ID'];
  orderNumber: Scalars['String'];
  originalPrice?: Maybe<Scalars['Float']>;
  originalPriceTotal?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  priceTotal?: Maybe<Scalars['Float']>;
  productCategory: Scalars['String'];
  takeRate: Scalars['Float'];
  taxRate: Scalars['Float'];
  title: Scalars['String'];
  variantTitle: Scalars['String'];
};

/** The connection type for SalesReportLineItem. */
export type SalesReportLineItemConnection = {
  __typename?: 'SalesReportLineItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SalesReportLineItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SalesReportLineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SalesReportLineItemEdge = {
  __typename?: 'SalesReportLineItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SalesReportLineItem>;
};

export enum SalesReportSortKeys {
  Month = 'MONTH',
  TotalSales = 'TOTAL_SALES'
}

export enum SalesReportStatus {
  Fixed = 'FIXED',
  Pending = 'PENDING'
}

/** Autogenerated input type of SalesReportUnFix */
export type SalesReportUnFixInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  month: Scalars['String'];
  shopId: Scalars['ID'];
};

/** Autogenerated return type of SalesReportUnFix */
export type SalesReportUnFixPayload = {
  __typename?: 'SalesReportUnFixPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  salesReport: SalesReport;
};

/** Autogenerated input type of SalesReportsBulkFix */
export type SalesReportsBulkFixInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  month: Scalars['String'];
};

/** Autogenerated return type of SalesReportsBulkFix */
export type SalesReportsBulkFixPayload = {
  __typename?: 'SalesReportsBulkFixPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
};

/** Autogenerated input type of SalesReportsExportRequestCreate */
export type SalesReportsExportRequestCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  yearMonth: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of SalesReportsExportRequestCreate */
export type SalesReportsExportRequestCreatePayload = {
  __typename?: 'SalesReportsExportRequestCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
};

export type SalesReportsMetadata = {
  __typename?: 'SalesReportsMetadata';
  adjustments: Scalars['String'];
  lastUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  platformFee: Scalars['String'];
  saleDiscount: Scalars['String'];
  shopReward: Scalars['String'];
  totalSales: Scalars['String'];
  totalSalesWithoutTax: Scalars['String'];
};

/** Autogenerated input type of SalesReportsRefresh */
export type SalesReportsRefreshInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  yearMonth: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of SalesReportsRefresh */
export type SalesReportsRefreshPayload = {
  __typename?: 'SalesReportsRefreshPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
};

export type SearchDictionary = {
  __typename?: 'SearchDictionary';
  createdAt: Scalars['ISO8601DateTime'];
  dictionaryType: Scalars['String'];
  id: Scalars['ID'];
  searchTag?: Maybe<SearchTag>;
  synonyms?: Maybe<Array<SearchSynonym>>;
  term: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of SearchDictionaryAddSynonym */
export type SearchDictionaryAddSynonymPayload = {
  __typename?: 'SearchDictionaryAddSynonymPayload';
  searchDictionary: SearchDictionary;
};

/** The connection type for SearchDictionary. */
export type SearchDictionaryConnection = {
  __typename?: 'SearchDictionaryConnection';
  /** A list of edges. */
  edges: Array<SearchDictionaryEdge>;
  /** A list of nodes. */
  nodes: Array<SearchDictionary>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of SearchDictionaryCreate */
export type SearchDictionaryCreatePayload = {
  __typename?: 'SearchDictionaryCreatePayload';
  searchDictionary: SearchDictionary;
};

/** Autogenerated return type of SearchDictionaryDelete */
export type SearchDictionaryDeletePayload = {
  __typename?: 'SearchDictionaryDeletePayload';
  deletedSearchDictionaryId: Scalars['ID'];
};

/** An edge in a connection. */
export type SearchDictionaryEdge = {
  __typename?: 'SearchDictionaryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SearchDictionary;
};

export type SearchDictionaryInput = {
  dictionaryType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  synonyms?: InputMaybe<Array<SearchSynonymInput>>;
  term?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SearchDictionaryRemoveSynonym */
export type SearchDictionaryRemoveSynonymPayload = {
  __typename?: 'SearchDictionaryRemoveSynonymPayload';
  searchDictionary: SearchDictionary;
};

/** Autogenerated return type of SearchDictionaryUpdate */
export type SearchDictionaryUpdatePayload = {
  __typename?: 'SearchDictionaryUpdatePayload';
  searchDictionary: SearchDictionary;
};

export type SearchSynonym = {
  __typename?: 'SearchSynonym';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  term: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of SearchSynonymCreate */
export type SearchSynonymCreatePayload = {
  __typename?: 'SearchSynonymCreatePayload';
  searchSynonym: SearchSynonym;
};

/** Autogenerated return type of SearchSynonymDelete */
export type SearchSynonymDeletePayload = {
  __typename?: 'SearchSynonymDeletePayload';
  deletedSearchSynonymId: Scalars['ID'];
};

export type SearchSynonymInput = {
  id?: InputMaybe<Scalars['ID']>;
  term?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SearchSynonymUpdate */
export type SearchSynonymUpdatePayload = {
  __typename?: 'SearchSynonymUpdatePayload';
  searchSynonym: SearchSynonym;
};

export type SearchTag = {
  __typename?: 'SearchTag';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  products: ProductConnection;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type SearchTagProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of SearchTagAddProduct */
export type SearchTagAddProductPayload = {
  __typename?: 'SearchTagAddProductPayload';
  searchTag: SearchTag;
};

/** Autogenerated return type of SearchTagBulkAddProduct */
export type SearchTagBulkAddProductPayload = {
  __typename?: 'SearchTagBulkAddProductPayload';
  searchTag: SearchTag;
};

/** The connection type for SearchTag. */
export type SearchTagConnection = {
  __typename?: 'SearchTagConnection';
  /** A list of edges. */
  edges: Array<SearchTagEdge>;
  /** A list of nodes. */
  nodes: Array<SearchTag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of SearchTagCreate */
export type SearchTagCreatePayload = {
  __typename?: 'SearchTagCreatePayload';
  searchTag: SearchTag;
};

/** Autogenerated return type of SearchTagDelete */
export type SearchTagDeletePayload = {
  __typename?: 'SearchTagDeletePayload';
  deletedSearchTagId: Scalars['ID'];
};

/** An edge in a connection. */
export type SearchTagEdge = {
  __typename?: 'SearchTagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SearchTag;
};

/** Autogenerated return type of SearchTagRemoveProduct */
export type SearchTagRemoveProductPayload = {
  __typename?: 'SearchTagRemoveProductPayload';
  searchTag: SearchTag;
};

/** Autogenerated return type of SearchTagUpdate */
export type SearchTagUpdatePayload = {
  __typename?: 'SearchTagUpdatePayload';
  searchTag: SearchTag;
};

export type Season = {
  __typename?: 'Season';
  autumn: Scalars['Boolean'];
  spring: Scalars['Boolean'];
  summer: Scalars['Boolean'];
  winter: Scalars['Boolean'];
};

export type SeasonInput = {
  autumn: Scalars['Boolean'];
  spring: Scalars['Boolean'];
  summer: Scalars['Boolean'];
  winter: Scalars['Boolean'];
};

export type ShippingConfig = {
  __typename?: 'ShippingConfig';
  id?: Maybe<Scalars['ID']>;
  shops?: Maybe<Array<Brand>>;
  targetHour?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of ShippingConfigCreate */
export type ShippingConfigCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  targetHour: Scalars['Int'];
};

/** Autogenerated return type of ShippingConfigCreate */
export type ShippingConfigCreatePayload = {
  __typename?: 'ShippingConfigCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shippingConfig: ShippingConfig;
};

/** Autogenerated input type of ShippingConfigUpdate */
export type ShippingConfigUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  targetHour: Scalars['Int'];
};

/** Autogenerated return type of ShippingConfigUpdate */
export type ShippingConfigUpdatePayload = {
  __typename?: 'ShippingConfigUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shippingConfig: ShippingConfig;
};

export enum ShopAccount {
  Corporate = 'CORPORATE',
  Personal = 'PERSONAL',
  Unknown = 'UNKNOWN'
}

export type ShopAccountStatusHistory = {
  __typename?: 'ShopAccountStatusHistory';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  shop: Brand;
  staff?: Maybe<Staff>;
  status: AccountStatus;
};

/** Autogenerated input type of ShopAccountStatusUpdate */
export type ShopAccountStatusUpdateInput = {
  accountStatus: AccountStatus;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  shopId: Scalars['ID'];
};

/** Autogenerated return type of ShopAccountStatusUpdate */
export type ShopAccountStatusUpdatePayload = {
  __typename?: 'ShopAccountStatusUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shop: Brand;
};

/** Autogenerated input type of ShopActivate */
export type ShopActivateInput = {
  autoCreateCollection: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  defaultCategory: ProductType;
  defaultTakeRate: Scalars['Float'];
  defaultTaxRate: Scalars['Float'];
  inventoryManagementTypes: Array<InventoryManagementTypeInput>;
  logisticsCompanyId: Scalars['ID'];
  shopBio?: InputMaybe<Scalars['String']>;
  shopBioJa?: InputMaybe<Scalars['String']>;
  shopId: Scalars['ID'];
  shopNameKana: Scalars['String'];
  vendors: Array<VendorInput>;
};

/** Autogenerated return type of ShopActivate */
export type ShopActivatePayload = {
  __typename?: 'ShopActivatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shop: Brand;
};

/** Autogenerated input type of ShopInformationUpdate */
export type ShopInformationUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactInformation?: InputMaybe<Scalars['String']>;
  corporateAddress?: InputMaybe<Scalars['String']>;
  corporateNumber?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  itemReturnAddress?: InputMaybe<Scalars['String']>;
  newInJapan?: InputMaybe<Scalars['Boolean']>;
  personInCharge?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  shopBio?: InputMaybe<Scalars['String']>;
  shopCoverImage?: InputMaybe<ImageInput>;
  shopName?: InputMaybe<Scalars['String']>;
  shopNotice?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ShopInformationUpdate */
export type ShopInformationUpdatePayload = {
  __typename?: 'ShopInformationUpdatePayload';
  brand: Brand;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ShopInput = {
  account?: InputMaybe<ShopAccount>;
  accountStatus?: InputMaybe<AccountStatus>;
  collectionId?: InputMaybe<Scalars['ID']>;
  companyName?: InputMaybe<Scalars['String']>;
  corporateAddress?: InputMaybe<Scalars['String']>;
  corporateNumber?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<ImageInput>;
  defaultCategory?: InputMaybe<ProductType>;
  defaultTakeRate?: InputMaybe<Scalars['Float']>;
  defaultTaxRate?: InputMaybe<Scalars['Float']>;
  groups?: InputMaybe<Array<GroupInput>>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  inventoryManagementTypes?: InputMaybe<Array<InventoryManagementTypeInput>>;
  itemReturnAddress?: InputMaybe<Scalars['String']>;
  logisticsCompanyId?: InputMaybe<Scalars['ID']>;
  newInJapan?: InputMaybe<Scalars['Boolean']>;
  personInCharge?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<RegionInput>;
  rewardPaymentMethodTypes?: InputMaybe<Array<RewardPaymentMethodTypeInput>>;
  shippingConfigId?: InputMaybe<Scalars['ID']>;
  shopBio?: InputMaybe<Scalars['String']>;
  shopBioJa?: InputMaybe<Scalars['String']>;
  shopMemo?: InputMaybe<Scalars['String']>;
  shopName?: InputMaybe<Scalars['String']>;
  shopNameKana?: InputMaybe<Scalars['String']>;
  shopNote?: InputMaybe<Scalars['String']>;
  shopNotice?: InputMaybe<Scalars['String']>;
  shopType?: InputMaybe<ShopType>;
  superiorShop?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of ShopIssueCreate */
export type ShopIssueCreateInput = {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<ImageInput>>;
  lineItemIds?: InputMaybe<Array<Scalars['ID']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

/** Autogenerated return type of ShopIssueCreate */
export type ShopIssueCreatePayload = {
  __typename?: 'ShopIssueCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  issue: Issue;
};

/** Autogenerated input type of ShopIssueMessageCreate */
export type ShopIssueMessageCreateInput = {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  issueId: Scalars['ID'];
};

/** Autogenerated return type of ShopIssueMessageCreate */
export type ShopIssueMessageCreatePayload = {
  __typename?: 'ShopIssueMessageCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: IssueMessage;
};

/** Autogenerated input type of ShopIssueMessagesMarkAllAsRead */
export type ShopIssueMessagesMarkAllAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  issueId: Scalars['ID'];
};

/** Autogenerated return type of ShopIssueMessagesMarkAllAsRead */
export type ShopIssueMessagesMarkAllAsReadPayload = {
  __typename?: 'ShopIssueMessagesMarkAllAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ShopIssueUpdate */
export type ShopIssueUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of ShopIssueUpdate */
export type ShopIssueUpdatePayload = {
  __typename?: 'ShopIssueUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  issue: Issue;
};

export type ShopOnboardingState = {
  __typename?: 'ShopOnboardingState';
  brandInformation: Scalars['Boolean'];
  businessInformation: Scalars['Boolean'];
  financialInformation: Scalars['Boolean'];
  inReview: Scalars['Boolean'];
  readyForReview: Scalars['Boolean'];
  rejected: Scalars['Boolean'];
  shopInformation: Scalars['Boolean'];
};

export enum ShopOnboardingStep {
  BrandInformation = 'BRAND_INFORMATION',
  BusinessInformation = 'BUSINESS_INFORMATION',
  Complete = 'COMPLETE',
  FinancialInformation = 'FINANCIAL_INFORMATION',
  InReview = 'IN_REVIEW',
  ReadyForReview = 'READY_FOR_REVIEW',
  Rejected = 'REJECTED',
  ShopInformation = 'SHOP_INFORMATION'
}

/** Autogenerated input type of ShopProductVariantUpdateQuantity */
export type ShopProductVariantUpdateQuantityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  inventoryQuantity: Scalars['Int'];
};

/** Autogenerated return type of ShopProductVariantUpdateQuantity */
export type ShopProductVariantUpdateQuantityPayload = {
  __typename?: 'ShopProductVariantUpdateQuantityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  productVariant: ProductVariant;
};

export type ShopSale = {
  __typename?: 'ShopSale';
  endsAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  itemCount: Scalars['Int'];
  maxSaleRate?: Maybe<Scalars['Float']>;
  shop: Brand;
  shopSaleProductVariants: Array<ShopSaleProductVariant>;
  shopSaleStatusHistories: Array<ShopSaleStatusHistory>;
  startsAt: Scalars['ISO8601DateTime'];
  status: ShopSaleStatus;
  tag: Scalars['String'];
  title: Scalars['String'];
};

/** Autogenerated input type of ShopSaleConfirm */
export type ShopSaleConfirmInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  tag?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ShopSaleConfirm */
export type ShopSaleConfirmPayload = {
  __typename?: 'ShopSaleConfirmPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shopSale: ShopSale;
};

/** The connection type for ShopSale. */
export type ShopSaleConnection = {
  __typename?: 'ShopSaleConnection';
  /** A list of edges. */
  edges: Array<ShopSaleEdge>;
  /** A list of nodes. */
  nodes: Array<ShopSale>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopSaleEdge = {
  __typename?: 'ShopSaleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopSale;
};

/** Autogenerated input type of ShopSaleForceStop */
export type ShopSaleForceStopInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ShopSaleForceStop */
export type ShopSaleForceStopPayload = {
  __typename?: 'ShopSaleForceStopPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shopSale: ShopSale;
};

export type ShopSaleProductVariant = {
  __typename?: 'ShopSaleProductVariant';
  afterSalePrice: Scalars['Int'];
  id: Scalars['ID'];
  productVariantId: Scalars['ID'];
  salePrice: Scalars['Int'];
  variant: ProductVariant;
};

/** Autogenerated input type of ShopSaleReapply */
export type ShopSaleReapplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ShopSaleReapply */
export type ShopSaleReapplyPayload = {
  __typename?: 'ShopSaleReapplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shopSale: ShopSale;
};

export enum ShopSaleStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Ended = 'ENDED',
  EndJobApplying = 'END_JOB_APPLYING',
  OnSale = 'ON_SALE',
  Scheduled = 'SCHEDULED',
  ScheduledReapply = 'SCHEDULED_REAPPLY',
  StartJobApplying = 'START_JOB_APPLYING',
  Submitted = 'SUBMITTED'
}

export type ShopSaleStatusHistory = {
  __typename?: 'ShopSaleStatusHistory';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  status: ShopSaleStatus;
};

/** Autogenerated input type of ShopSaleStatusUpdate */
export type ShopSaleStatusUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  status: ShopSaleStatus;
};

/** Autogenerated return type of ShopSaleStatusUpdate */
export type ShopSaleStatusUpdatePayload = {
  __typename?: 'ShopSaleStatusUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shopSale: ShopSale;
};

/** Autogenerated input type of ShopSaleUpdate */
export type ShopSaleUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  shopSaleVariants?: InputMaybe<Array<ShopSaleVariantInput>>;
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ShopSaleUpdate */
export type ShopSaleUpdatePayload = {
  __typename?: 'ShopSaleUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shopSale: ShopSale;
};

export type ShopSaleVariantInput = {
  productVariantId: Scalars['ID'];
  salePrice: Scalars['Int'];
};

/** Autogenerated input type of ShopSellmateIntegrationRequestCreate */
export type ShopSellmateIntegrationRequestCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ShopSellmateIntegrationRequestCreate */
export type ShopSellmateIntegrationRequestCreatePayload = {
  __typename?: 'ShopSellmateIntegrationRequestCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ShopShopTermsOfUseAccept */
export type ShopShopTermsOfUseAcceptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ShopShopTermsOfUseAccept */
export type ShopShopTermsOfUseAcceptPayload = {
  __typename?: 'ShopShopTermsOfUseAcceptPayload';
  accepted: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum ShopSortType {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DefaultTakeRateAsc = 'DEFAULT_TAKE_RATE_ASC',
  DefaultTakeRateDesc = 'DEFAULT_TAKE_RATE_DESC',
  DefaultTaxRateAsc = 'DEFAULT_TAX_RATE_ASC',
  DefaultTaxRateDesc = 'DEFAULT_TAX_RATE_DESC',
  ShopNameAsc = 'SHOP_NAME_ASC',
  ShopNameDesc = 'SHOP_NAME_DESC'
}

export type ShopTermsOfUse = {
  __typename?: 'ShopTermsOfUse';
  acceptanceCount: Scalars['Int'];
  id: Scalars['ID'];
  languages: Array<ShopTermsOfUseLanguage>;
  published: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['String'];
};

/** The connection type for ShopTermsOfUse. */
export type ShopTermsOfUseConnection = {
  __typename?: 'ShopTermsOfUseConnection';
  /** A list of edges. */
  edges: Array<ShopTermsOfUseEdge>;
  /** A list of nodes. */
  nodes: Array<ShopTermsOfUse>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of ShopTermsOfUseCreate */
export type ShopTermsOfUseCreatePayload = {
  __typename?: 'ShopTermsOfUseCreatePayload';
  shopTermsOfUse: ShopTermsOfUse;
};

/** An edge in a connection. */
export type ShopTermsOfUseEdge = {
  __typename?: 'ShopTermsOfUseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopTermsOfUse;
};

export type ShopTermsOfUseInput = {
  id?: InputMaybe<Scalars['ID']>;
  languages?: InputMaybe<Array<ShopTermsOfUseLanguageInput>>;
  version?: InputMaybe<Scalars['String']>;
};

export type ShopTermsOfUseLanguage = {
  __typename?: 'ShopTermsOfUseLanguage';
  body: Scalars['String'];
  id: Scalars['ID'];
  language: Language;
};

export type ShopTermsOfUseLanguageInput = {
  body?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  languageId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of ShopTermsOfUsePublish */
export type ShopTermsOfUsePublishInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ShopTermsOfUsePublish */
export type ShopTermsOfUsePublishPayload = {
  __typename?: 'ShopTermsOfUsePublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shopTermsOfUse: ShopTermsOfUse;
};

/** Autogenerated return type of ShopTermsOfUseUpdate */
export type ShopTermsOfUseUpdatePayload = {
  __typename?: 'ShopTermsOfUseUpdatePayload';
  shopTermsOfUse: ShopTermsOfUse;
};

/** Autogenerated input type of ShopThirdPartyServiceCredentialCreate */
export type ShopThirdPartyServiceCredentialCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  serviceType: Scalars['String'];
  shopId: Scalars['String'];
};

/** Autogenerated return type of ShopThirdPartyServiceCredentialCreate */
export type ShopThirdPartyServiceCredentialCreatePayload = {
  __typename?: 'ShopThirdPartyServiceCredentialCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  thirdPartyServiceCredential: ThirdPartyServiceCredential;
};

export enum ShopType {
  Brand = 'BRAND',
  Select = 'SELECT'
}

/** Autogenerated return type of ShopUpdate */
export type ShopUpdatePayload = {
  __typename?: 'ShopUpdatePayload';
  brand: Brand;
};

/** Autogenerated input type of ShopVendorCreate */
export type ShopVendorCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  vendors: Array<VendorInput>;
};

/** Autogenerated return type of ShopVendorCreate */
export type ShopVendorCreatePayload = {
  __typename?: 'ShopVendorCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vendors: Array<Vendor>;
};

/** Autogenerated input type of ShopVendorDelete */
export type ShopVendorDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorIds: Array<Scalars['String']>;
};

/** Autogenerated return type of ShopVendorDelete */
export type ShopVendorDeletePayload = {
  __typename?: 'ShopVendorDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vendors: Array<Vendor>;
};

export type ShopVendorSize = {
  __typename?: 'ShopVendorSize';
  countUsedBy?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  itemCategory: ItemCategory;
  name: Scalars['String'];
  originalSize?: Maybe<Size>;
  shop: Brand;
  vendor: Vendor;
};

/** Autogenerated input type of ShopVendorSizeCreate */
export type ShopVendorSizeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemCategoryId: Scalars['ID'];
  name: Scalars['String'];
  originalSizeId?: InputMaybe<Scalars['ID']>;
  shopId?: InputMaybe<Scalars['ID']>;
  vendorId: Scalars['ID'];
};

/** Autogenerated return type of ShopVendorSizeCreate */
export type ShopVendorSizeCreatePayload = {
  __typename?: 'ShopVendorSizeCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  size: ShopVendorSize;
};

/** Autogenerated input type of ShopVendorSizeDelete */
export type ShopVendorSizeDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ShopVendorSizeDelete */
export type ShopVendorSizeDeletePayload = {
  __typename?: 'ShopVendorSizeDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  size: ShopVendorSize;
};

export type ShopVendorSizeItemCategorySizes = {
  __typename?: 'ShopVendorSizeItemCategorySizes';
  itemCategory: ItemCategory;
  sizes: Array<ShopVendorSize>;
};

export type ShopVendorSizeMaster = {
  __typename?: 'ShopVendorSizeMaster';
  itemCategories: Array<ShopVendorSizeItemCategorySizes>;
  shop: Brand;
  vendor: Vendor;
};

/** The connection type for ShopVendorSizeMaster. */
export type ShopVendorSizeMasterConnection = {
  __typename?: 'ShopVendorSizeMasterConnection';
  /** A list of edges. */
  edges: Array<ShopVendorSizeMasterEdge>;
  /** A list of nodes. */
  nodes: Array<ShopVendorSizeMaster>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ShopVendorSizeMasterEdge = {
  __typename?: 'ShopVendorSizeMasterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ShopVendorSizeMaster;
};

/** Autogenerated input type of ShopVendorSizeUpdate */
export type ShopVendorSizeUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  originalSizeId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of ShopVendorSizeUpdate */
export type ShopVendorSizeUpdatePayload = {
  __typename?: 'ShopVendorSizeUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  size: ShopVendorSize;
};

export type ShopifyProduct = {
  __typename?: 'ShopifyProduct';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleEn?: Maybe<Scalars['String']>;
};

export type ShopifyProductImage = {
  __typename?: 'ShopifyProductImage';
  id: Scalars['ID'];
  sortOrder?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type ShopifyProductVariant = {
  __typename?: 'ShopifyProductVariant';
  id?: Maybe<Scalars['ID']>;
  originalPrice?: Maybe<Scalars['Int']>;
  packingRackNames?: Maybe<Array<Scalars['String']>>;
  price?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
};

export type ShortcutContentSectionInput = {
  adminTitle: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  items: Array<ShortcutContentSectionItemInput>;
  option?: InputMaybe<Scalars['String']>;
  pageIds: Array<Scalars['ID']>;
  region: RegionInput;
  status: ContentSectionStatus;
};

export type ShortcutContentSectionItem = {
  __typename?: 'ShortcutContentSectionItem';
  destination?: Maybe<ContentSectionItemDestination>;
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  iconImageUrl: Scalars['String'];
  id: Scalars['ID'];
  platform: Platform;
  sortOrder: Scalars['Int'];
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
  title: Scalars['String'];
};

export type ShortcutContentSectionItemInput = {
  destination?: InputMaybe<ContentSectionItemDestinationInput>;
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  iconImage: ImageInput;
  id?: InputMaybe<Scalars['ID']>;
  platform: PlatformInput;
  sortOrder: Scalars['Int'];
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  title: Scalars['String'];
};

export enum Sign {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export type Size = {
  __typename?: 'Size';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SizeChart = {
  __typename?: 'SizeChart';
  columns: Array<Scalars['String']>;
  rows: Array<Array<Scalars['String']>>;
};

export type SizeChartFormat = {
  __typename?: 'SizeChartFormat';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SizeChartInput = {
  columns: Array<Scalars['String']>;
  rows: Array<Array<Scalars['String']>>;
};

export type Staff = {
  __typename?: 'Staff';
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Scalars['String']>;
};

export type Styling = {
  __typename?: 'Styling';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  images: Array<StylingImage>;
  model?: Maybe<StylingModel>;
  modelGender?: Maybe<StylingModelGender>;
  modelHeight: Scalars['Int'];
  productVariants: Array<ProductVariant>;
  shop?: Maybe<Brand>;
  staff?: Maybe<Staff>;
  status: StylingStatus;
};

/** The connection type for Styling. */
export type StylingConnection = {
  __typename?: 'StylingConnection';
  /** A list of edges. */
  edges: Array<StylingEdge>;
  /** A list of nodes. */
  nodes: Array<Styling>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of StylingCreate */
export type StylingCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  images: Array<Scalars['String']>;
  modelGender?: InputMaybe<StylingModelGender>;
  modelHeight: Scalars['Int'];
  modelId?: InputMaybe<Scalars['ID']>;
  productVariantIds: Array<Scalars['ID']>;
  shopId?: InputMaybe<Scalars['ID']>;
  status: StylingStatus;
};

/** Autogenerated return type of StylingCreate */
export type StylingCreatePayload = {
  __typename?: 'StylingCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  stylingId: Scalars['ID'];
};

/** Autogenerated input type of StylingDelete */
export type StylingDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of StylingDelete */
export type StylingDeletePayload = {
  __typename?: 'StylingDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedStylingId: Scalars['Int'];
};

/** An edge in a connection. */
export type StylingEdge = {
  __typename?: 'StylingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Styling;
};

export type StylingImage = {
  __typename?: 'StylingImage';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type StylingModel = {
  __typename?: 'StylingModel';
  gender?: Maybe<StylingModelGender>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  instagramId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  profileImageUrl: Scalars['String'];
  profileText?: Maybe<Scalars['String']>;
  stylings?: Maybe<Array<Styling>>;
  tiktokId?: Maybe<Scalars['String']>;
  twitterId?: Maybe<Scalars['String']>;
  youtubeId?: Maybe<Scalars['String']>;
};

/** The connection type for StylingModel. */
export type StylingModelConnection = {
  __typename?: 'StylingModelConnection';
  /** A list of edges. */
  edges: Array<StylingModelEdge>;
  /** A list of nodes. */
  nodes: Array<StylingModel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of StylingModelCreate */
export type StylingModelCreatePayload = {
  __typename?: 'StylingModelCreatePayload';
  stylingModel: StylingModel;
};

/** An edge in a connection. */
export type StylingModelEdge = {
  __typename?: 'StylingModelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StylingModel;
};

export enum StylingModelGender {
  Men = 'MEN',
  Other = 'OTHER',
  Women = 'WOMEN'
}

export type StylingModelInput = {
  gender: StylingModelGender;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  instagramId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<ImageInput>;
  profileText?: InputMaybe<Scalars['String']>;
  tiktokId?: InputMaybe<Scalars['String']>;
  twitterId?: InputMaybe<Scalars['String']>;
  youtubeId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of StylingModelUpdate */
export type StylingModelUpdatePayload = {
  __typename?: 'StylingModelUpdatePayload';
  stylingModel: StylingModel;
};

export enum StylingStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

/** Autogenerated input type of StylingUpdate */
export type StylingUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  modelGender?: InputMaybe<StylingModelGender>;
  modelHeight: Scalars['Int'];
  modelId?: InputMaybe<Scalars['ID']>;
  productVariantIds: Array<Scalars['ID']>;
  shopId?: InputMaybe<Scalars['ID']>;
  status: StylingStatus;
};

/** Autogenerated return type of StylingUpdate */
export type StylingUpdatePayload = {
  __typename?: 'StylingUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  stylingId: Scalars['ID'];
};

export type StylingsStatusCount = {
  __typename?: 'StylingsStatusCount';
  draft: Scalars['Int'];
  published: Scalars['Int'];
};

export type ThirdPartyServiceCredential = {
  __typename?: 'ThirdPartyServiceCredential';
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  expiredAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  serviceType: Scalars['String'];
};

export type TopBannerContentSectionInput = {
  adminTitle: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  items: Array<TopBannerContentSectionItemInput>;
  option?: InputMaybe<Scalars['String']>;
  pageIds: Array<Scalars['ID']>;
  region: RegionInput;
  status: ContentSectionStatus;
};

export type TopBannerContentSectionItem = {
  __typename?: 'TopBannerContentSectionItem';
  destination?: Maybe<ContentSectionItemDestination>;
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  eyecatchImageUrl: Scalars['String'];
  id: Scalars['ID'];
  platform: Platform;
  sortOrder: Scalars['Int'];
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TopBannerContentSectionItemInput = {
  destination: ContentSectionItemDestinationInput;
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  eyecatchImage: ImageInput;
  id?: InputMaybe<Scalars['ID']>;
  platform: PlatformInput;
  sortOrder: Scalars['Int'];
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TopRestockRequestItem = {
  __typename?: 'TopRestockRequestItem';
  count?: Maybe<Scalars['Int']>;
  variant?: Maybe<ProductVariant>;
};

/** The connection type for TopRestockRequestItem. */
export type TopRestockRequestItemConnection = {
  __typename?: 'TopRestockRequestItemConnection';
  /** A list of edges. */
  edges: Array<TopRestockRequestItemEdge>;
  /** A list of nodes. */
  nodes: Array<TopRestockRequestItem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TopRestockRequestItemEdge = {
  __typename?: 'TopRestockRequestItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TopRestockRequestItem;
};

/** Autogenerated return type of Update */
export type UpdatePayload = {
  __typename?: 'UpdatePayload';
  productCollection: ProductCollection;
};

export type VariantInput = {
  id?: InputMaybe<Scalars['ID']>;
  inventoryQuantity?: InputMaybe<Scalars['String']>;
  option1: VariantOptionInput;
  option2: VariantOptionInput;
  sku: Scalars['String'];
};

export type VariantOptionInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type Vendor = {
  __typename?: 'Vendor';
  areas: Array<Area>;
  banners: Array<VendorBanner>;
  bio?: Maybe<Scalars['String']>;
  bioJa?: Maybe<Scalars['String']>;
  bodyHtml?: Maybe<Scalars['String']>;
  brandType: VendorBrandType;
  collectionId?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  itemGenres: Array<ItemGenre>;
  logoImageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameKana?: Maybe<Scalars['String']>;
  productsCount: Scalars['Int'];
  shopsCount: Scalars['Int'];
  status: VendorStatus;
  vendorLookImageUrls: Array<Scalars['String']>;
};

export type VendorAreaInput = {
  id: Scalars['ID'];
};

export type VendorBanner = {
  __typename?: 'VendorBanner';
  eyecatchImageUrl: Scalars['String'];
  id: Scalars['ID'];
  url: Scalars['String'];
};

/** Autogenerated return type of VendorBannerCreate */
export type VendorBannerCreatePayload = {
  __typename?: 'VendorBannerCreatePayload';
  vendorBanner: VendorBanner;
};

/** Autogenerated return type of VendorBannerDelete */
export type VendorBannerDeletePayload = {
  __typename?: 'VendorBannerDeletePayload';
  deletedVendorBannerId: Scalars['ID'];
};

export type VendorBannerInput = {
  eyecatchImage?: InputMaybe<ImageInput>;
  id?: InputMaybe<Scalars['ID']>;
  url: Scalars['String'];
  vendorId: Scalars['ID'];
};

/** Autogenerated return type of VendorBannerUpdate */
export type VendorBannerUpdatePayload = {
  __typename?: 'VendorBannerUpdatePayload';
  vendorBanner: VendorBanner;
};

export enum VendorBrandType {
  Collaboration = 'COLLABORATION',
  Normal = 'NORMAL'
}

/** The connection type for Vendor. */
export type VendorConnection = {
  __typename?: 'VendorConnection';
  /** A list of edges. */
  edges: Array<VendorEdge>;
  /** A list of nodes. */
  nodes: Array<Vendor>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VendorEdge = {
  __typename?: 'VendorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Vendor;
};

export type VendorInput = {
  areas?: InputMaybe<Array<VendorAreaInput>>;
  autoCreateCollection?: InputMaybe<Scalars['Boolean']>;
  bio?: InputMaybe<Scalars['String']>;
  bioJa?: InputMaybe<Scalars['String']>;
  bodyHtml?: InputMaybe<Scalars['String']>;
  brandType?: InputMaybe<VendorBrandType>;
  collectionId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<Scalars['String']>;
  handle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  itemGenres?: InputMaybe<Array<ItemGenreInput>>;
  logoImage?: InputMaybe<ImageInput>;
  name: Scalars['String'];
  nameKana?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VendorStatus>;
  vendorLookImages?: InputMaybe<Array<ImageInput>>;
};

export enum VendorSortType {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export enum VendorStatus {
  Activated = 'ACTIVATED',
  Archived = 'ARCHIVED'
}

/** Autogenerated return type of VendorUpdate */
export type VendorUpdatePayload = {
  __typename?: 'VendorUpdatePayload';
  vendor: Vendor;
};

export type WiseRequirementBanks = {
  __typename?: 'WiseRequirementBanks';
  key: Scalars['String'];
  name: Scalars['String'];
};

export type WiseRequirementCountries = {
  __typename?: 'WiseRequirementCountries';
  key: Scalars['String'];
  name: Scalars['String'];
};

export type WiseRequirements = {
  __typename?: 'WiseRequirements';
  banks: Array<WiseRequirementBanks>;
  countries: Array<WiseRequirementCountries>;
  rawJson: Scalars['Json'];
};
